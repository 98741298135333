import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  TimelineMark,
  TimelineTimePart,
} from 'web/src/modules/sportline/submodules/core-statistic/types';
import type {
  BetlineMatchStatisticsTeam,
  BetlinePostMatchStatistics,
} from 'web/src/modules/sportline/types/rest';
import useSwipeByDragAndDropComposable from 'web/src/components/SwipeByDragAndDrop/mixins/useSwipeByDragAndDropComposable';
import { SportFamily } from 'web/src/modules/sportline/enums';
import {
  filterEmptyMark,
  marksApproximation,
  parseCardToMark,
  parseGoalToMark,
  sortMarksByAsc,
  splitMarksByTimes,
} from 'web/src/modules/sportline/submodules/core-statistic/utils';
import useMatchStatisticsTeams
  from 'web/src/modules/sportline/submodules/event-statistic/composables/useMatchStatisticsTeams';
import {
  getWholeMatchTimeForSportFamily,
} from 'web/src/modules/sportline/utils/timeline';

interface PostMatchStatisticTimelineProps {
  postMatchStatistic: Ref<BetlinePostMatchStatistics>;
}

interface PostMatchStatisticTimelineComposable {
  teams: Ref<Maybe<[BetlineMatchStatisticsTeam, BetlineMatchStatisticsTeam]>>;
  times: Ref<TimelineTimePart[]>;
  onMouseDown(event: MouseEvent): void;
}

export default function usePostMatchStatisticTimeline(
  props: PostMatchStatisticTimelineProps,
): PostMatchStatisticTimelineComposable {
  const { swipeByDnDonMouseDown } = useSwipeByDragAndDropComposable();

  const { teams } = useMatchStatisticsTeams(props.postMatchStatistic);
  const times = computed<TimelineTimePart[]>(() => {
    const sportFamily = SportFamily.Soccer;
    const wholeMatchTime = getWholeMatchTimeForSportFamily(sportFamily);

    if (!wholeMatchTime) { return []; }

    const cardMarks: TimelineMark[] = props.postMatchStatistic.value.cards
      .map<Maybe<TimelineMark>>((card) => parseCardToMark(card, { sportFamily }))
      .filter<TimelineMark>(filterEmptyMark);
    const goalsMarks: TimelineMark[] = props.postMatchStatistic.value.goals
      .map<Maybe<TimelineMark>>((goal) => parseGoalToMark(goal, { sportFamily }))
      .filter<TimelineMark>(filterEmptyMark);

    return splitMarksByTimes(sortMarksByAsc([...cardMarks, ...goalsMarks]), props.postMatchStatistic.value.scores)
      .map((time) => ({
        ...time,
        marks: marksApproximation(time.marks, {
          minDistance: 5,
          minPosition: 2,
          maxPosition: 98,
        }),
      }));
  });

  function onMouseDown(event: MouseEvent): void {
    swipeByDnDonMouseDown(event);
  }

  return {
    teams,
    times,
    onMouseDown,
  };
}
