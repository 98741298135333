import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SportEventDetailsSlideId } from 'web/src/modules/sportline/submodules/event-details/types';
import type { SportlineEvent } from 'web/src/modules/sportline/types';
import { HeadlineSlideName } from 'web/src/modules/sportline/submodules/event-details/enums';

interface UseAvailableHeadlineSlidesProps {
  sportlineEvent: Ref<Maybe<SportlineEvent>>;
  isStreamAvailable: Ref<boolean>;
  isLiveWidgetAvailable: Ref<boolean>;
}

interface UseAvailableHeadlineSlidesComposable {
  switcherSlidesIds: Ref<SportEventDetailsSlideId[]>;
}

export function useAvailableHeadlineSlides(
  props: UseAvailableHeadlineSlidesProps,
): UseAvailableHeadlineSlidesComposable {
  const {
    sportlineEvent,
    isStreamAvailable,
    isLiveWidgetAvailable,
  } = props;

  const switcherSlidesIds = computed<SportEventDetailsSlideId[]>(() => {
    const slides: SportEventDetailsSlideId[] = [];

    if (sportlineEvent.value) { slides.push({ id: HeadlineSlideName.Info }); }
    if (isStreamAvailable.value) { slides.push({ id: HeadlineSlideName.LiveStream }); }
    if (isLiveWidgetAvailable.value) { slides.push({ id: HeadlineSlideName.LiveWidget }); }

    return slides;
  });

  return {
    switcherSlidesIds,
  };
}
