import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import type { AppVueRouter, AsyncAbstractPrefetchResult } from '@core/router';

// composables
import { useSnackbarsStore } from 'web/src/modules/snackbars/store';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { useRedirectBySportsTree } from 'web/src/modules/sportline/submodules/redirects/composables';
// utils
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';

import { GetEventSubscriptionAction } from '../../utils/requests-counter/enums';
import BaseSportEventDetailsPageNavigationService from './BaseSportEventDetailsPageNavigationService';
import { useSportEventDetailsNavigationBackButton } from './useSportEventDetailsNavigationBackButton';

/**
 * Handle navigation for user
 * Check missing event and redirect
 */
export default class DefaultSportEventDetailsPageNavigationService
  extends BaseSportEventDetailsPageNavigationService {
  async prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
  ): AsyncAbstractPrefetchResult {
    const superResult = await super.prefetch(router, to);
    if (superResult !== undefined) {
      return superResult as RouteLocationRaw;
    }
    useSportEventDetailsNavigationBackButton().activateBackButton();
    // do not await handle
    this.handleRedirectsForEmptyPage(router, to);
    void useSportlineEventDetailsStore().initialRequests();
  }

  protected handleRedirectsForEmptyPage(router: AppVueRouter, to: RouteLocationNormalized): void {
    const { sportEventId } = getSportlinePageNavigationParameters(to);
    const { showConnectionError } = useSnackbarsStore();

    if (!sportEventId) {
      return;
    }

    const {
      createOnInitBehaviour,
      sendVisitPageAnalytics,
    } = useSportlineEventDetailsStore();

    const { onInitBehaviours } = this;

    createOnInitBehaviour(sportEventId, {
      [GetEventSubscriptionAction.ShowError](): void {
        void showConnectionError();
        onInitBehaviours?.[GetEventSubscriptionAction.ShowError]?.();
      },
      [GetEventSubscriptionAction.Redirect](): void {
        void useRedirectBySportsTree({
          router,
          code: 301,
          allowedTargets: {
            sport: true,
            region: true,
            league: true,
          },
          canShowRedirectedInfoTargets: {
            league: true,
          },
        }).make().finally(() => {
          onInitBehaviours?.[GetEventSubscriptionAction.Redirect]?.();
        });
      },
      [GetEventSubscriptionAction.Finish](): void {
        sendVisitPageAnalytics();
        onInitBehaviours?.[GetEventSubscriptionAction.Finish]?.();
      },
    });
  }
}
