<script lang="ts" setup>
import { toRef } from 'vue';

// composables
import { useInfoSlideType } from 'web/src/modules/sportline/submodules/event-details/composables/headline';
// types
import type { Sport, SportlineEvent } from 'web/src/modules/sportline/types';
// enums
import { HeadlineSlideType } from 'web/src/modules/sportline/submodules/event-details/enums';
// utils
import { isLiveSportlineEvent } from 'web/src/modules/sportline/utils/sportlineEvent/sportlineTypeCheck';

import LiveMatchInfo from './wide/LiveMatchInfo.vue';
import OutrightInfo from './wide/OutrightInfo.vue';
import PreMatchInfo from './wide/PreMatchInfo.vue';

interface WideInfoProps {
  sportlineEvent: SportlineEvent;
  sport: Sport;
  backgroundColor?: Maybe<string>;
}

interface WideInfoSlots {
  'primary-market'(props: { hasTimeline?: boolean; hasCountdown?: boolean }): void;
}

const props = withDefaults(defineProps<WideInfoProps>(), {
  backgroundColor: null,
});

defineSlots<WideInfoSlots>();

const sportlineEvent = toRef(() => props.sportlineEvent);
const sport = toRef(() => props.sport);
const backgroundColor = toRef(() => props.backgroundColor);

const { infoSlideType } = useInfoSlideType({ sportlineEvent });
</script>

<template>
  <OutrightInfo
    v-if="infoSlideType === HeadlineSlideType.OutrightInfo"
    :sportline-event="sportlineEvent"
    :background-color="backgroundColor"
  >
    <template
      v-if="$slots['primary-market']"
      #primary-market
    >
      <slot name="primary-market" />
    </template>
  </OutrightInfo>
  <LiveMatchInfo
    v-else-if="infoSlideType === HeadlineSlideType.LiveInfo && isLiveSportlineEvent(sportlineEvent)"
    :sportline-event="sportlineEvent"
    :sport="sport"
    :background-color="backgroundColor"
  >
    <template
      v-if="$slots['primary-market']"
      #primary-market="{ hasTimeline }"
    >
      <slot
        name="primary-market"
        :has-timeline="hasTimeline"
      />
    </template>
  </LiveMatchInfo>
  <PreMatchInfo
    v-else-if="infoSlideType === HeadlineSlideType.PreMatchInfo"
    :sportline-event="sportlineEvent"
    :background-color="backgroundColor"
  >
    <template
      v-if="$slots['primary-market']"
      #primary-market
    >
      <slot
        name="primary-market"
        :has-countdown="true"
      />
    </template>
  </PreMatchInfo>
</template>
