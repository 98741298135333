import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { SportElement, SportEventsCounters } from 'web/src/modules/sportline/types';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { filterSportsListEvents, getSportElementEventsCounters } from 'web/src/modules/sportline/utils';

interface UseSimilarEventsSectionProps {
  doNotSplitOutrightList: Ref<boolean>;
}

interface UseSimilarEventsSectionComposable {
  sportElement: Ref<Maybe<SportElement>>;
  isDisplayBlock: Ref<boolean>;
  isDisplayTitle: Ref<boolean>;
  isOnlyOutrightShown: Ref<boolean>;
  activeEventId: Ref<Maybe<string>>;
}

export function useSimilarEventsSection(props: UseSimilarEventsSectionProps): UseSimilarEventsSectionComposable {
  const { doNotSplitOutrightList } = props;

  const sportlineEventDetailsStore = useSportlineEventDetailsStore();
  const sportlineEvent = toRef(sportlineEventDetailsStore, 'sportEventDetails');
  const sportElement = toRef(sportlineEventDetailsStore, 'relatedSportListElement');

  const activeEventId = computed<Maybe<string>>(() => (sportlineEvent.value?.id ?? null));
  const isOnlyOutrightShown = computed<boolean>(() => !doNotSplitOutrightList.value && !!sportlineEvent.value?.isOutright);

  /**
   * It seems deprecated. The related list will come from the server without the event
   */
  const filteredSportElement = computed<Maybe<SportElement>>(() => {
    const element = sportElement.value;

    if (!element) {
      return null;
    }

    const result = filterSportsListEvents([element], (sportEventElement) => sportEventElement.sportEvent.id !== activeEventId.value);

    return result[0] ?? null;
  });

  const sportEventsCounters = computed<Maybe<SportEventsCounters>>(() => {
    const element = filteredSportElement.value;
    return element ? getSportElementEventsCounters(element) : null;
  });

  const isDisplayBlock = computed<boolean>(() => {
    const counters = sportEventsCounters.value;

    if (!counters) {
      return false;
    }

    if (doNotSplitOutrightList.value) {
      return counters.total > 0;
    }

    return isOnlyOutrightShown.value
      ? (counters.outright + counters.liveOutright) > 0
      : counters.total > 0;
  });

  /**
   * For outright event:
   * - we display only "Same events" title (and show only outrights)
   *
   * For other event type:
   * - we display "Same events" title only for non-empty block
   * - and "Outrights" title only for non-empty outrights block
   */
  const isDisplayTitle = computed<boolean>(() => {
    const counters = sportEventsCounters.value;

    if (!counters) {
      return false;
    }

    if (doNotSplitOutrightList.value) {
      return counters.total > 0;
    }

    return isOnlyOutrightShown.value
      ? (counters.outright + counters.liveOutright) > 0
      : (counters.live + counters.prematch) > 0;
  });

  return {
    sportElement: filteredSportElement,
    isDisplayBlock,
    isDisplayTitle,
    activeEventId,
    isOnlyOutrightShown,
  };
}
