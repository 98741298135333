import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type {
  UseInfoWidgetLoadingController,
} from 'web/src/modules/sportline/submodules/event-details/types';
import type {
  SportlineWidgetType,
} from 'web/src/modules/sportline/types';
import {
  sportlineWidgetTypeOddin,
} from 'web/src/modules/sportline/constants/widgets';

interface UseOddinPrematchWidgetLoadingControllerProps {
  liveWidgetType: Ref<Optional<SportlineWidgetType>>;
}

export function useOddinPrematchWidgetLoadingController(
  props: UseOddinPrematchWidgetLoadingControllerProps,
): UseInfoWidgetLoadingController {
  const { liveWidgetType } = props;

  const isWidgetReady = ref(false);

  const isWidgetAvailable = computed(() => (liveWidgetType.value === sportlineWidgetTypeOddin));

  function setIsWidgetReady(value: boolean): void {
    isWidgetReady.value = value;
  }

  return {
    isWidgetAvailable,
    isWidgetReady,
    setIsWidgetReady,
  };
}
