import type { Ref } from 'vue';

import { useTheme } from '@core/theme';

import type { IFrameWidgetName } from 'web/src/modules/framed-app/types';
import { useFramedWidgetUrl } from 'web/src/modules/widgets/composables/useFramedWidgetUrl';

export function createFramedAppWidgetUrl(iframeName: IFrameWidgetName): Ref<string | undefined> {
  const { theme } = useTheme();
  return useFramedWidgetUrl(iframeName, theme);
}
