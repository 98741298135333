<script lang="ts" setup>
import { computed, toRef, watch } from 'vue';

import { VLazyIframe as VIframe } from 'web/src/components/Iframe';

import type {
  OddinWidgetEmits,
  OddinWidgetProps,
} from '../types';
import { useOddinLiveWidget } from './useOddinLiveWidget';

const props = defineProps<OddinWidgetProps>();
const emit = defineEmits<OddinWidgetEmits>();

const iframeSrc = toRef(() => props.iframeSrc);

const {
  minHeight,
  iframeName,
  iframedAppSrc,
  additionalQueryStringParameters,
  onIframeMounted,
  onIframeUnmounted,
} = useOddinLiveWidget({ iframeSrc }, emit);

const paddingSize = '1' ? 38 : 22;
const finalHeight = computed(() => minHeight.value ? `${minHeight.value + paddingSize}px` : undefined);
watch(finalHeight, (height) => {
  emit('size-changed', { height });
});
</script>

<template>
  <div v-auto-id="'OddinLiveWidget'"
    v-data-test="{ el: 'oddin-live-widget', src: iframeSrc }"
    :class="$style['oddin-live-widget']"
    :style="{ height: finalHeight }"
  >
    <VIframe
      v-if="iframedAppSrc"
      full-height
      full-width
      :src="iframedAppSrc"
      :name="iframeName"
      :additional-query-string-parameters="additionalQueryStringParameters"
      @vue:mounted="onIframeMounted"
      @vue:unmounted="onIframeUnmounted"
    />
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .oddin-live-widget {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 100%;
    padding: if($isDesktop, 16px, 8px);
    overflow: hidden;
  }
}
</style>
