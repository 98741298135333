<script setup lang="ts">
import { toRef } from 'vue';

import type { BetgeniusWidgetProps } from 'web/src/modules/sportline/submodules/widgets/types';
import { VLazyIframe as VIframe } from 'web/src/components/Iframe';
import { betgeniusIframeWidgetName } from 'web/src/modules/framed-app/constants';

import { useBetgeniusWidget } from './useBetgeniusWidget';
import WidgetWrapper from './WidgetWrapper.vue';

const props = defineProps<BetgeniusWidgetProps>();

const isFloatingModeEnabled = toRef(props, 'isFloatingModeEnabled');
const isFloatingModeAllowed = toRef(props, 'isFloatingModeAllowed');
const isActive = toRef(props, 'isActive', false);
const liveWidget = toRef(props, 'liveWidget');
const locale = toRef(props, 'locale');

const {
  readyToLoading,
  iframeWidgetSrc,
  additionalQueryStringParameters,
} = useBetgeniusWidget({
  isActive,
  liveWidget,
  locale,
});
</script>

<template>
  <div v-auto-id="'BetgeniusWidget'" :class="$style['gs-pitch-widget']">
    <WidgetWrapper
      :class="$style['gs-pitch-widget__component']"
      :is-floating-mode-allowed="isFloatingModeAllowed"
      :is-floating-mode-enabled="isFloatingModeEnabled"
      is-flex-size
    >
      <VIframe
        v-if="readyToLoading && iframeWidgetSrc"
        :src="iframeWidgetSrc"
        :additional-query-string-parameters="additionalQueryStringParameters"
        :name="betgeniusIframeWidgetName"
        :class="$style['gs-pitch-widget__iframe-holder']"
        :iframe-class="$style['gs-pitch-widget__iframe']"
        flex-layout
        full-width
      />
    </WidgetWrapper>
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .gs-pitch-widget {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;

    &__component {
      display: flex;
      width: 100%;
      min-height: 350px;

      @if $isDesktop {
        flex: none;
        height: 100%;
      } @else {
        flex: 1 0 auto;
      }
    }

    &__iframe {
      @if not $isDesktop {
        min-height: 280px;
        max-height: 350px;
      }
    }

    &__iframe-holder {
      @if not $isDesktop {
        flex: 1 0 auto;
      }
    }
  }
}
</style>
