<script lang="ts" setup>
import { toRef } from 'vue';

import type {
  BetlinePostMatchStatistics,
} from 'web/src/modules/sportline/types/rest';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';

import usePostMatchStatisticSimpleResultScore from './usePostMatchStatisticSimpleResultScore';

interface PostMatchStatisticSimpleResultScoreProps {
  postMatchStatistic: BetlinePostMatchStatistics;
  isDisplayScoresByTimes?: boolean;
  isDisplayScoresBySets?: boolean;
}

const props = defineProps<PostMatchStatisticSimpleResultScoreProps>();
const postMatchStatistic = toRef(props, 'postMatchStatistic');
const isDisplayScoresByTimes = toRef(props, 'isDisplayScoresByTimes', false);
const isDisplayScoresBySets = toRef(props, 'isDisplayScoresBySets', false);

const {
  teams,
  resultScore,
  scoresDetails,
  overtimeScores,
} = usePostMatchStatisticSimpleResultScore({
  postMatchStatistic,
  isDisplayScoresByTimes,
  isDisplayScoresBySets,
});
</script>

<template>
  <div v-auto-id="'PostMatchStatisticSimpleResultScore'"
    v-if="teams"
    :class="$style['post-match-statistic-simple-result-score']"
  >
    <div :class="$style['post-match-statistic-simple-result-score__teams']">
      <div :class="$style['post-match-statistic-simple-result-score__team']">
        <StatisticTeamLogo
          :class="$style['post-match-statistic-simple-result-score__team-logo']"
          :team="teams[0]"
        />

        <div :class="$style['post-match-statistic-simple-result-score__team-name']">
          {{ teams[0].name }}
        </div>
      </div>

      <div :class="$style['post-match-statistic-simple-result-score__team']">
        <StatisticTeamLogo
          :class="$style['post-match-statistic-simple-result-score__team-logo']"
          :team="teams[1]"
        />

        <div :class="$style['post-match-statistic-simple-result-score__team-name']">
          {{ teams[1].name }}
        </div>
      </div>
    </div>

    <div :class="$style['post-match-statistic-simple-result-score__right']">
      <template v-if="isDisplayScoresBySets">
        <template
          v-for="(score, index) in scoresDetails"
          :key="`by-sets-${index}`"
        >
          <div :class="$style['post-match-statistic-simple-result-score__scores']">
            <div :class="$style['post-match-statistic-simple-result-score__score']">
              {{ score[0] }}
            </div>
            <div :class="$style['post-match-statistic-simple-result-score__score']">
              {{ score[1] }}
            </div>
          </div>
        </template>
      </template>

      <div
        v-if="resultScore"
        :class="[
          $style['post-match-statistic-simple-result-score__scores'],
          $style['post-match-statistic-simple-result-score__scores--final'],
        ]"
      >
        <div :class="$style['post-match-statistic-simple-result-score__score']">
          {{ resultScore[0] }}
        </div>
        <div :class="$style['post-match-statistic-simple-result-score__score']">
          {{ resultScore[1] }}
        </div>
      </div>

      <template v-if="isDisplayScoresByTimes">
        <template
          v-for="(score, index) in scoresDetails"
          :key="`by-times-${index}`"
        >
          <div :class="$style['post-match-statistic-simple-result-score__scores']">
            <div :class="$style['post-match-statistic-simple-result-score__score']">
              {{ score[0] }}
            </div>
            <div :class="$style['post-match-statistic-simple-result-score__score']">
              {{ score[1] }}
            </div>
          </div>
        </template>

        <div
          v-if="overtimeScores"
          :class="$style['post-match-statistic-simple-result-score__scores']"
        >
          <div :class="$style['post-match-statistic-simple-result-score__score']">
            {{ overtimeScores[0] }}
          </div>
          <div :class="$style['post-match-statistic-simple-result-score__score']">
            {{ overtimeScores[1] }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" module>
.post-match-statistic-simple-result-score {
  display: flex;
  place-content: center space-between;
  padding: 16px 8px;
  background-color: var(--Layer2);
  border-radius: 5px;

  &__teams {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &__team {
    display: flex;
    justify-content: flex-start;

    &:first-child {
      margin-bottom: 8px;
    }
  }

  &__team-name {
    @include medium\14\16\025;

    padding-left: 8px;
    color: var(--TextDefault);
  }

  &__team-logo {
    width: 16px;
    height: 16px;
  }

  &__right {
    display: flex;
    justify-content: space-around;
  }

  &__scores {
    @include bold\13\16\-07;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 8px;
    color: var(--TextSecondary);
    text-align: right;

    &--final {
      color: var(--TextDefault);
    }

    &:last-child {
      margin: 0;
    }
  }

  &__score {
    &:first-child {
      margin-bottom: 8px;
    }
  }
}
</style>
