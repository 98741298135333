<script setup lang="ts">
import { computed, toRef } from 'vue';

import ResizeObserver from 'web/src/components/ResizeObserver/ResizeObserver.vue';

import type {
  HeadlineWideLayoutProps,
  HeadlineWideLayoutSlots,
} from './types';
import {
  useCustomWidgetHeight,
  useCybersportInplayComponentHeight,
  useHeadlineCybersportLayout,
  useHeadlineWideLayout,
} from './composables';

const props = defineProps<HeadlineWideLayoutProps>();
defineSlots<HeadlineWideLayoutSlots>();

const sportlineEvent = toRef(props, 'sportEventDetails');
const hasPrimaryMarket = toRef(props, 'hasPrimaryMarket', false);
const hasInplayComponent = toRef(props, 'hasInplayComponent', false);

const {
  backgroundStyles,
  backgroundColor,
  streamAspectRatio,
} = useHeadlineCybersportLayout({ sportlineEvent });
const {
  backgroundLineType,
  headlineLayoutName,
} = useHeadlineWideLayout({
  autoLayoutAllowed: true,
  sportlineEvent,
  hasPrimaryMarket,
  hasInplayComponent,
});
const {
  customWidgetHeight,
  setHeight: setWidgetHeight,
} = useCustomWidgetHeight();
const layoutClassName = computed(() => `headline-layout--${backgroundLineType.value}-${headlineLayoutName.value}`);

const {
  customHeight,
  onInplayElementResize,
} = useCybersportInplayComponentHeight({ customWidgetHeight });
</script>

<template>
  <div v-auto-id="'HeadlineCybersportWideLayout'"
    :class="{
      [$style['headline-layout']]: true,
      [$style[layoutClassName]]: !!layoutClassName,
    }"
    :style="{ minHeight: customHeight, height: customHeight }"
  >
    <div
      :class="{
        [$style['headline-layout__info']]: true,
        [$style['headline-layout__background']]: true,
        [$style['headline-layout__info--full-width']]: !hasInplayComponent,
      }"
      :style="backgroundStyles"
    >
      <slot
        name="info-widget"
        :set-height="setWidgetHeight"
      />

      <slot
        name="info"
        :background-color="backgroundColor"
      />
    </div>

    <div
      v-if="hasInplayComponent"
      :class="$style['headline-layout__inplay-component']"
      :style="{
        'min-height': customHeight,
        'padding-top': `${Math.round(streamAspectRatio * 50)}%`,
        'background-color': backgroundColor ?? undefined,
      }"
    >
      <ResizeObserver
        :class="$style['headline-layout__inplay-component-content']"
        @resize="onInplayElementResize"
      >
        <slot
          name="inplay-component"
          :background-color="backgroundColor"
        />
      </ResizeObserver>
    </div>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/submodules/event-details/views/headline-layout/sportline-page-headline-layout';
@include headline-layout-colors-for-cybersport('.headline-layout');
@include wide-headline-layout('.headline-layout');

.headline-layout {
  $inplayComponent: #{&}__inplay-component;

  color: var(--White);

  &--live-auto {
    min-height: 200px;

    & #{$inplayComponent} {
      min-height: 200px;
    }
  }

  &--live-default {
    height: 204px;
    max-height: 200px;

    & #{$inplayComponent} {
      min-height: 200px;
    }
  }

  &--live-low {
    height: 172px;
    max-height: 172px;

    & #{$inplayComponent} {
      min-height: 172px;
    }
  }

  &--prematch-default {
    height: 172px;
    max-height: 172px;
  }

  &--prematch-low {
    height: 142px;
    max-height: 142px;
  }

  &--prematch--large {
    height: 204px;
    max-height: 200px;
  }

  &__info {
    flex-basis: 50%;
    min-width: 386px;
  }

  &__inplay-component {
    position: relative;
    flex-basis: 50%;
    min-width: 320px;
    height: 0;
    overflow: hidden;
    border-radius: 5px;
  }

  &__inplay-component-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}
</style>
