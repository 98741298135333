import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type {
  OnResizeElementPayload,
} from 'web/src/components/ResizeObserver/types';

import {
  pxToValue,
  valueToPx,
} from '../utils';

interface UseCybersportInplayComponentHeightProps {
  customWidgetHeight: Ref<Optional<string>>;
}

interface UseCybersportInplayComponentHeightComposable {
  onInplayElementResize(payload: OnResizeElementPayload): void;
  customHeight: Ref<Optional<string>>;
}

export function useCybersportInplayComponentHeight(
  props: UseCybersportInplayComponentHeightProps,
): UseCybersportInplayComponentHeightComposable {
  const { customWidgetHeight } = props;

  const customInplayComponentHeight = ref<Optional<number>>(undefined);

  const customHeight = computed<Optional<string>>(() => {
    const widgetHeight = pxToValue(customWidgetHeight.value);
    const streamHeight = customInplayComponentHeight.value;

    if (!widgetHeight) {
      return undefined;
    }

    if (!streamHeight) {
      return customWidgetHeight.value;
    }

    return valueToPx(Math.max(widgetHeight, streamHeight));
  });

  function onInplayElementResize(payload: OnResizeElementPayload): void {
    customInplayComponentHeight.value = payload?.height;
  }

  return {
    onInplayElementResize,
    customHeight,
  };
}
