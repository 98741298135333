import type { RouteLocationNormalized } from 'vue-router';

import { isString } from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AbstractPrefetchResult, AppVueRouter } from '@core/router';

// composables
import { useIsDevIP } from 'web/src/modules/core/composables/root';
import { useSportlineBreadcrumbsStore } from 'web/src/modules/sportline/submodules/breadcrumbs/store';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';
// types
import type { RequestBehaviourActionsMap } from 'web/src/modules/sportline/submodules/event-details/store/composables';
import type { SportEventDetailsPageNavigationService } from 'web/src/modules/sportline/submodules/event-details/types';
import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';
// guards
import { isValidSportEventsPageIdentifier } from 'web/src/modules/sportline/guards';
// utils
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';

/**
 * Default details page navigation logic
 * Load data and handle navigation parameters
 */
export default class BaseSportEventDetailsPageNavigationService implements SportEventDetailsPageNavigationService {
  constructor(
    protected onInitBehaviours?: RequestBehaviourActionsMap,
  ) {}

  prefetch(
    _: AppVueRouter,
    to: RouteLocationNormalized,
  ): AbstractPrefetchResult {
    const navigationParameters: SportEventsPageIdentifier = getSportlinePageNavigationParameters(to);

    if (!isValidSportEventsPageIdentifier(navigationParameters)) {
      return { name: RouteName.ERROR_NOT_FOUND_404 };
    }

    const { isDevIP } = useIsDevIP();

    const statisticId = isDevIP.value && isString(to.query?.statisticId)
      ? to.query?.statisticId
      : undefined;

    useSportlineEventDetailsStore().setPageIdentifier({ ...navigationParameters, statisticId });
    useSportlineEventStatisticStore().setPageIdentifier({ ...navigationParameters, statisticId });
    useSportlineBreadcrumbsStore().setPageIdentifier({ ...navigationParameters, statisticId });
    void useSportlineWidgetsStore().fetchSportlineConfigs();
  }
}
