import type { Ref } from 'vue';
import { toRef } from 'vue';

import type { HeadlineSlideName } from 'web/src/modules/sportline/submodules/event-details/enums';
import type { SportEventDetails, SportEventWidgetConfig } from 'web/src/modules/sportline/submodules/event-details/types';
import type {
  League,
  LiveWidget,
  Region,
  Sport,
  SportElement,
  SportlineWidgetType,
} from 'web/src/modules/sportline/types';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';

interface UseSportlineEventDetailsHeadlineDataComposable {
  sportlineEvent: Ref<Maybe<SportEventDetails>>;
  sportElement: Ref<Maybe<SportElement>>;
  sport: Ref<Maybe<Sport>>;
  region: Ref<Maybe<Region>>;
  league: Ref<Maybe<League>>;
  doShowStreamWidget: Ref<boolean>;
  isStreamFloatingAvailable: Ref<boolean>;
  defaultSwiperSlideId: Ref<HeadlineSlideName>;
  widgetConfig: Ref<SportEventWidgetConfig>;
  liveWidgetType: Ref<Optional<SportlineWidgetType>>;
  liveMatchTrackerWidget: Ref<Maybe<LiveWidget>>;
  reloadStreamInfo(): Promise<void>;
  onHeadlineSlideChanged(slideId: HeadlineSlideName): void;
}

export function useSportlineEventDetailsHeadlineData(): UseSportlineEventDetailsHeadlineDataComposable {
  const sportlineEventDetailsStore = useSportlineEventDetailsStore();

  const sportlineEvent = toRef(() => sportlineEventDetailsStore.sportEventDetails);
  const sportElement = toRef(() => sportlineEventDetailsStore.sportElement);
  const sport = toRef(() => sportlineEventDetailsStore.sport);
  const region = toRef(() => sportlineEventDetailsStore.region);
  const league = toRef(() => sportlineEventDetailsStore.league);
  const isStreamIndicatorAvailable = toRef(() => sportlineEventDetailsStore.isStreamIndicatorAvailable);
  const isStreamFloatingAvailable = toRef(() => sportlineEventDetailsStore.isStreamFloatingAvailable);
  const defaultSwiperSlideId = toRef(() => sportlineEventDetailsStore.defaultSwiperSlideId);
  const widgetConfig = toRef(() => sportlineEventDetailsStore.sportEventWidgetConfig);
  const liveWidgetType = toRef(() => sportlineEventDetailsStore.liveWidgetType);
  const liveMatchTrackerWidget = toRef(() => sportlineEventDetailsStore.liveMatchTrackerWidget);

  const {
    saveSwiperSlideId: onHeadlineSlideChanged,
    reloadStream: reloadStreamInfo,
  } = sportlineEventDetailsStore;

  return {
    sportlineEvent,
    sportElement,
    sport,
    region,
    league,
    doShowStreamWidget: isStreamIndicatorAvailable,
    isStreamFloatingAvailable,
    defaultSwiperSlideId,
    widgetConfig,
    liveMatchTrackerWidget,
    liveWidgetType,
    reloadStreamInfo,
    onHeadlineSlideChanged,
  };
}
