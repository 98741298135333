<script setup lang="ts">
import { ref } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VIcon } from '@components/v-icon';

import type { TwoStateIndicatorConfig } from 'web/src/components/_shared';
import type {
  SportEventDetailsRelatedEventsDropdownRef,
} from 'web/src/modules/sportline/submodules/event-details/views/toolbar/types';
import VFavoriteToggle from 'web/src/components/FavoriteToggle/VFavoriteToggle/VFavoriteToggle.vue';
import RelatedEventsDropdown
  from 'web/src/modules/sportline/submodules/event-details/components/related-events/RelatedEventsDropdown.vue';

interface SportlineEventDetailsToolbarContentProps {
  name?: Maybe<string>;
  region?: Maybe<string>;
  isFavorite?: boolean;
  hasToggleButton?: boolean;
  hasFavoriteButton?: boolean;
  isOnlyPendingFavorite?: boolean;
}

interface SportlineEventDetailsToolbarContentEmits {
  (e: 'toggle-favorite'): void;
}

defineProps<SportlineEventDetailsToolbarContentProps>();
const emit = defineEmits<SportlineEventDetailsToolbarContentEmits>();

const dropdown = ref<Optional<SportEventDetailsRelatedEventsDropdownRef>>();

const iconSize = '1' ? IconSize.SIZE_24 : IconSize.SIZE_20;
const dropdownIconSize = '1' ? IconSize.SIZE_24 : IconSize.SIZE_16;
const expandIconProperties: TwoStateIndicatorConfig = [{
  size: dropdownIconSize,
  name: IconName.EXPAND_DOWN,
}, {
  size: dropdownIconSize,
  name: IconName.EXPAND_UP,
}];
const activeFavoriteIcon: VIconProps = {
  name: IconName.STAR,
  size: iconSize,
};
const inactiveFavoriteIcon: VIconProps = {
  name: IconName.STAR_OUTLINE,
  size: iconSize,
};

function hide(): void {
  dropdown.value?.hide();
}
</script>

<template>
  <div v-auto-id="'SportlineEventDetailsToolbarContent'" :class="$style['sport-event-toolbar']">
    <slot name="backButton" />
    <RelatedEventsDropdown
      v-if="hasToggleButton"
      ref="dropdown"
    >
      <template #reference="{ opened }">
        <div :class="$style['sport-event-toolbar__dropdown-container']">
          <div :class="$style['sport-event-toolbar__label']">
            <span
              v-if="null"
              :class="$style['sport-event-toolbar__region']"
            >{{ region }}</span>
            <div
              :class="$style['sport-event-toolbar__wrapper']"
            >
              <span :class="$style['sport-event-toolbar__name']">{{ name ?? '' }}</span>
              <VIcon
                :class="$style['sport-event-toolbar__expand-button']"
                v-bind="opened ? expandIconProperties[1] : expandIconProperties[0]"
              />
            </div>
          </div>
        </div>
      </template>
      <slot
        name="relatedList"
        :hide="hide"
      />
    </RelatedEventsDropdown>
    <div
      v-else
      :class="$style['sport-event-toolbar__label']"
    >
      <span
        v-if="null"
        :class="$style['sport-event-toolbar__region']"
      >{{ region ?? '' }}</span>
      <span :class="$style['sport-event-toolbar__name']">{{ name ?? '' }}</span>
    </div>

    <div :class="$style['sport-event-toolbar__button-container']">
      <VFavoriteToggle
        v-if="hasFavoriteButton"
        :active-icon="activeFavoriteIcon"
        :inactive-icon="inactiveFavoriteIcon"
        :is-favorite="isFavorite"
        is-button
        :is-only-pending="isOnlyPendingFavorite"
        @click="emit('toggle-favorite')"
      />
    </div>
  </div>
</template>

<style module lang="scss">
.sport-event-toolbar {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  min-height: 44px;

  &__region {
    @include regular\13\16;

    display: inline-block;
    margin-right: 4px;
    color: var(--TextPrimary);
  }

  &__label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 14px 8px;
  }

  &__wrapper {
    display: flex;
  }

  &__name {
    @include medium\13\16;

    color: var(--TextDefault);
  }

  &__button-container {
    display: flex;
    align-items: flex-start;
  }

  &__expand-button {
    margin-left: 8px;
  }

  &__dropdown-container {
    height: 100%;
  }

  @include is-app-layout-desktop {
    padding-right: 0;
    margin-bottom: 8px;

    .sport-event-toolbar {
      &__name {
        @include medium\18\20;

        color: var(--TextDefault);
      }

      &__region {
        margin-right: 0;
      }

      &__label {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 8px;
        text-align: center;
      }
    }
  }
}
</style>
