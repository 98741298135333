import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  BetlineMatchStatisticsTeam,
  BetlinePostMatchStatistics,
} from 'web/src/modules/sportline/types/rest';
import { BetlineStatisticScoresType } from 'web/src/modules/sportline/enums/rest';
import useMatchStatisticsTeams
  from 'web/src/modules/sportline/submodules/event-statistic/composables/useMatchStatisticsTeams';

interface UsePostMatchStatisticSimpleResultScoreProps {
  postMatchStatistic: Ref<BetlinePostMatchStatistics>;
  isDisplayScoresByTimes: Ref<boolean>;
  isDisplayScoresBySets: Ref<boolean>;
}

interface UsePostMatchStatisticSimpleResultScoreComposable {
  teams: Ref<Maybe<[BetlineMatchStatisticsTeam, BetlineMatchStatisticsTeam]>>;
  resultScore: Ref<Maybe<string[]>>;
  scoresDetails: Ref<string[][]>;
  overtimeScores: Ref<Maybe<string[]>>;
}

const specialScoresTypes = [
  BetlineStatisticScoresType.HalfTime,
  BetlineStatisticScoresType.FullTime,
  BetlineStatisticScoresType.OverTime,
  BetlineStatisticScoresType.StopTime,
  BetlineStatisticScoresType.Sets,
  BetlineStatisticScoresType.AfterPenalty,
];

export default function usePostMatchStatisticSimpleResultScore(
  props: UsePostMatchStatisticSimpleResultScoreProps,
): UsePostMatchStatisticSimpleResultScoreComposable {
  const { postMatchStatistic } = props;

  const { teams } = useMatchStatisticsTeams(postMatchStatistic);

  const resultScore = computed<Maybe<string[]>>(() => (postMatchStatistic.value.resultScore
    ? postMatchStatistic.value.resultScore.split(':')
    : null));
  const scoresDetails = computed<string[][]>(() => postMatchStatistic.value.scores
    .filter((score) => !!score.type
      && !!score.value
      && !specialScoresTypes.includes(score.type as BetlineStatisticScoresType))
    .map<string[]>((score) => (score.value || '0:0').split(':')));
  const overtimeScores = computed<Maybe<string[]>>(() => {
    const scoresOT = postMatchStatistic.value.scores
      .find((scores) => scores.type === BetlineStatisticScoresType.OverTime);
    const scoresFT = postMatchStatistic.value.scores
      .find((scores) => scores.type === BetlineStatisticScoresType.FullTime);

    if (!scoresOT || !scoresFT) {
      return null;
    }

    try {
      const scoresOTValue = (scoresOT.value || '0:0').split(':');
      const scoresFTValue = (scoresFT.value || '0:0').split(':');

      return [
        String(parseInt(scoresOTValue[0], 10) - parseInt(scoresFTValue[0], 10)),
        String(parseInt(scoresOTValue[1], 10) - parseInt(scoresFTValue[1], 10)),
      ];
    } catch {
      return null;
    }
  });

  return {
    teams,
    resultScore,
    scoresDetails,
    overtimeScores,
  };
}
