<script lang="ts" setup>
import { toRef } from 'vue';

import type {
  BetlinePostMatchStatistics,
} from 'web/src/modules/sportline/types/rest';
import ExpandButton from 'web/src/modules/sportline/submodules/core-statistic/components/ExpandButton.vue';
import { TimelineMarkType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
import PostMatchStatisticIncidentItem from 'web/src/modules/sportline/submodules/event-details/components/post-match-statistic/PostMatchStatisticIncidentItem.vue';

import usePostMatchStatisticIncidentsList from './usePostMatchStatisticIncidentsList';

interface PostMatchStatisticIncidentsListProps {
  postMatchStatistic: BetlinePostMatchStatistics;
}

const props = defineProps<PostMatchStatisticIncidentsListProps>();
const postMatchStatistic = toRef(props, 'postMatchStatistic');

const {
  isOpen,
  teams,
  marks,
} = usePostMatchStatisticIncidentsList({ postMatchStatistic });
</script>

<template>
  <div v-auto-id="'PostMatchStatisticIncidentsList'" v-if="teams">
    <div>
      <template v-if="isOpen">
        <template v-for="(mark, index) in marks">
          <div
            v-if="mark.type === TimelineMarkType.HALF_TIME"
            :key="`${mark.type}_${index}_HF`"
            :class="[
              $style['post-match-statistic-incident'],
              $style['post-match-statistic-incident--break'],
            ]"
          >
            <span :class="$style['post-match-statistic-incident__label']">{{ $t('WEB2_MATCH_STATISTIC_HALF_TIME') }}</span>
            <span :class="$style['post-match-statistic-incident__score']">{{ mark.label }}</span>
          </div>

          <div
            v-else-if="mark.type === TimelineMarkType.FULL_TIME"
            :key="`${mark.type}_${index}_FT`"
            :class="[
              $style['post-match-statistic-incident'],
              $style['post-match-statistic-incident--break'],
            ]"
          >
            <span :class="$style['post-match-statistic-incident__label']">{{ $t('WEB2_MATCH_STATISTIC_FULL_TIME') }}</span>
            <span :class="$style['post-match-statistic-incident__score']">{{ mark.label }}</span>
          </div>

          <div
            v-else-if="mark.type === TimelineMarkType.HALF_EXTRA_TIME"
            :key="`${mark.type}_${index}_HET`"
            :class="[
              $style['post-match-statistic-incident'],
              $style['post-match-statistic-incident--break'],
            ]"
          >
            <span :class="$style['post-match-statistic-incident__label']">{{ $t('WEB2_MATCH_STATISTIC_HALF_EXTRA_TIME') }}</span>
            <span :class="$style['post-match-statistic-incident__score']">{{ mark.label }}</span>
          </div>

          <div
            v-else-if="mark.type === TimelineMarkType.FULL_EXTRA_TIME"
            :key="`${mark.type}_${index}_FET`"
            :class="[
              $style['post-match-statistic-incident'],
              $style['post-match-statistic-incident--break'],
            ]"
          >
            <span :class="$style['post-match-statistic-incident__label']">{{ $t('WEB2_MATCH_STATISTIC_FULL_EXTRA_TIME') }}</span>
            <span :class="$style['post-match-statistic-incident__score']">{{ mark.label }}</span>
          </div>

          <div
            v-else-if="mark.type === TimelineMarkType.PENALTY_SHOOTOUT"
            :key="`${mark.type}_${index}_PS`"
            :class="[
              $style['post-match-statistic-incident'],
              $style['post-match-statistic-incident--break'],
            ]"
          >
            <span :class="$style['post-match-statistic-incident__label']">{{ $t('WEB2_MATCH_STATISTIC_PENALTY_SHOOTOUT') }}</span>
            <span :class="$style['post-match-statistic-incident__score']">{{ mark.label }}</span>
          </div>

          <template v-else-if="mark.type === TimelineMarkType.INCIDENTS">
            <PostMatchStatisticIncidentItem
              v-for="(incident, incidentIndex) in mark.hostIncidents"
              :key="`${mark.type}_${index}_${incident.competitorType}_${incident.type}_${incidentIndex}_incident`"
              :incident="incident"
              :team="teams[0]"
              :class="$style['post-match-statistic-incident']"
            />

            <PostMatchStatisticIncidentItem
              v-for="(incident, incidentIndex) in mark.guestIncidents"
              :key="`${mark.type}_${index}_${incident.competitorType}_${incident.type}_${incidentIndex}_incident`"
              :incident="incident"
              :team="teams[1]"
              :class="$style['post-match-statistic-incident']"
            />
          </template>
        </template>
      </template>

      <div
        :class="[
          $style['post-match-statistic-incident'],
          $style['post-match-statistic-incident--match-completed'],
        ]"
      >
        <span :class="$style['post-match-statistic-incident__label']">
          {{ $t('WEB2_MATCH_STATISTIC_MATCH_COMPLETED_WITH_SCORE') }}
        </span>
        <span :class="$style['post-match-statistic-incident__score']">{{ postMatchStatistic.resultScore }}</span>
      </div>
    </div>

    <ExpandButton
      v-if="marks.length > 0"
      v-model:is-expanded="isOpen"
      :opened-title="$t('WEB2_MATCH_STATISTIC_HIDE')"
      :closed-title="$t('WEB2_MATCH_STATISTIC_SHOW')"
      :class="$style['post-match-statistic-incidents-list-toggle']"
    />
  </div>
</template>

<style lang="scss" module>
.post-match-statistic-incident {
  padding: 8px;
  background-color: var(--Layer2);

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:not(:last-child) {
    margin-bottom: 1px;
  }

  &--break {
    display: flex;
    justify-content: center;
    background-color: var(--Highlight);
  }

  &--match-completed {
    display: flex;
    justify-content: center;
    background-color: var(--Layer2);
  }

  &__label {
    @include medium\13\16;

    margin: 0 4px;
    color: var(--TextDefault);
  }

  &__score {
    @include medium\13\16;

    margin: 0 4px;
    color: var(--TextSecondary);
  }
}

.post-match-statistic-incidents-list-toggle {
  margin-top: 8px;
}
</style>
