<script lang="ts" setup>
import { onBeforeUnmount, toRef } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { ButtonHeight, ButtonKind, VButton } from '@components/buttons';
import { VScrollbar } from '@components/v-scrollbar';

import { VSidebar } from 'web/src/components/Sidebar';
import LeagueBreadcrumbsItem from 'web/src/modules/sportline/submodules/breadcrumbs/components/LeagueBreadcrumbsItem.vue';
import RegionBreadcrumbsItem from 'web/src/modules/sportline/submodules/breadcrumbs/components/RegionBreadcrumbsItem.vue';
import RelatedEventsBreadcrumbsItem from 'web/src/modules/sportline/submodules/breadcrumbs/components/RelatedEventsBreadcrumbsItem.vue';
import SportBreadcrumbsItem from 'web/src/modules/sportline/submodules/breadcrumbs/components/SportBreadcrumbsItem.vue';
import {
  useActiveBreadcrumb,
  useFirstLeagueInSportElement,
  useSportlineBreadcrumbsLeagueItem,
  useSportlineBreadcrumbsMetaParameters,
  useSportlineBreadcrumbsRegionItem,
  useSportlineBreadcrumbsSportItem,
} from 'web/src/modules/sportline/submodules/breadcrumbs/composables';
import { BreadcrumbId } from 'web/src/modules/sportline/submodules/breadcrumbs/enums';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import SportsSidebarRouteComponent from 'web/src/modules/sportline/submodules/sidebar/views/SportsSidebarRouteComponent.vue';

import { useSportlineEventBreadcrumbs } from './useSportlineEventBreadcrumbs';

const sportlineEventDetailsStore = useSportlineEventDetailsStore();
const sportElement = toRef(sportlineEventDetailsStore, 'sportElement');

const { sport, region, league } = useFirstLeagueInSportElement({ sportElement });

const {
  activeId,
  toggleId,
} = useActiveBreadcrumb();
const {
  canNavigateTo,
  sportTreeElement,
  title: sportItemTitle,
  isShown: isSportItemShown,
  pageLocation: sportPageLocation,
  sportRepresentationFamily,
} = useSportlineBreadcrumbsSportItem({ sport });
const {
  regionElement,
  relatedRegions,
  title: regionItemTitle,
  isShown: isRegionItemShown,
  pageLocation: regionPageLocation,
} = useSportlineBreadcrumbsRegionItem({ region });
const {
  leagueElement,
  relatedLeagues,
  title: leagueItemTitle,
  isShown: isLeagueItemShown,
  pageLocation: leaguePageLocation,
} = useSportlineBreadcrumbsLeagueItem({ league });
const {
  isShown: isEventItemShown,
  title: eventItemTitle,
  sportEventDetailsId,
  leagueEventsList,
  isSidebarOpen,
  softCloseSidebar,
  toggleSidebar,
} = useSportlineEventBreadcrumbs();

useSportlineBreadcrumbsMetaParameters({
  sportElement: sportTreeElement,
  sportPageLocation,
  regionElement,
  regionPageLocation,
  leagueElement,
  leaguePageLocation,
});

onBeforeUnmount(() => {
  softCloseSidebar();
});
</script>

<template>
  <div v-auto-id="'SportlineEventBreadcrumbs'"
    :class="{
      [$style['sportline-event-breadcrumbs--sidebar-open']]: isSidebarOpen,
    }"
  >
    <VSidebar :class="$style['sportline-event-breadcrumbs__sidebar']">
      <div :class="$style['sportline-event-breadcrumbs__sidebar-close']">
        <VButton
          :icon-name="IconName.CROSS_PADDED"
          :icon-size="IconSize.SIZE_20"
          :kind="ButtonKind.TRANSPARENT"
          :height="ButtonHeight.MEDIUM"
          :class="$style['sportline-event-breadcrumbs__sidebar-close-button']"
          @click="toggleSidebar"
        />
      </div>
      <VScrollbar v-show="isSidebarOpen || process.env.VUE_APP_PRERENDER">
        <SportsSidebarRouteComponent />
      </VScrollbar>
    </VSidebar>

    <div :class="$style['sportline-breadcrumbs__inner']">
      <div :class="$style['sportline-event-breadcrumbs__left']">
        <div :class="$style['sportline-event-breadcrumbs__hamburger']">
          <VButton
            :icon-name="IconName.HAMBURGER_FULL"
            :icon-size="IconSize.SIZE_20"
            :kind="ButtonKind.TRANSPARENT"
            :height="ButtonHeight.MEDIUM"
            :class="$style['sportline-event-breadcrumbs__hamburger-button']"
            @click="toggleSidebar"
          />
        </div>

        <ol :class="$style['sportline-breadcrumbs__content']">
          <SportBreadcrumbsItem
            v-if="isSportItemShown"
            :key="sportTreeElement ? sportTreeElement.sport.id : 'unselectedSport'"
            :title="sportItemTitle"
            :to="canNavigateTo ? sportPageLocation : undefined"
            :position="1"
            :sport-family="sportRepresentationFamily"
            is-menu
          />

          <RegionBreadcrumbsItem
            v-if="isRegionItemShown"
            :key="regionElement?.region.id ?? 'unselectedRegion'"
            :active-region-id="regionElement?.region.id"
            :related-regions="relatedRegions"
            :title="regionItemTitle"
            :to="canNavigateTo ? regionPageLocation : undefined"
            :position="2"
            :open="activeId === BreadcrumbId.Regions"
            @toggle="toggleId"
          />

          <LeagueBreadcrumbsItem
            v-if="isLeagueItemShown"
            :key="leagueElement ? leagueElement.league.id : 'unselectedLeague'"
            :league-element="leagueElement"
            :related-leagues="relatedLeagues"
            :title="leagueItemTitle"
            :to="canNavigateTo ? leaguePageLocation : undefined"
            :position="3"
            :open="activeId === BreadcrumbId.Leagues"
            @toggle="toggleId"
          />

          <RelatedEventsBreadcrumbsItem
            v-if="isEventItemShown"
            :key="sportEventDetailsId || 'unselectedSportEvent'"
            :sport-element="sportElement"
            :related-events-list="leagueEventsList"
            :active-event-id="sportEventDetailsId"
            :title="eventItemTitle"
            :position="4"
            :open="activeId === BreadcrumbId.Events"
            @toggle="toggleId"
          />
        </ol>
      </div>
    </div>

    <transition name="opacity">
      <div
        v-if="isSidebarOpen"
        :class="$style['sportline-event-breadcrumbs__mask']"
        @click="toggleSidebar"
      />
    </transition>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/submodules/breadcrumbs/styles/sportline-breadcrumbs';

.sportline-event-breadcrumbs {
  $self: &;

  &__sidebar {
    @include z-index(sidebar-menu);

    position: absolute;
    top: 0;
    bottom: 0;
    width: 252px;
    margin: 8px 16px 0 0;
    background-color: var(--Layer0);
    box-shadow: var(--BreadcrumbBoxShadow);
    transform: translateX(-100vw);

    // @TODO fix close animation
    transition: transform 0.15s $animationCubicBezier;
  }

  &--sidebar-open &__sidebar {
    transform: translateX(0);
  }

  &__sidebar-close {
    position: absolute;
    top: 8px;
    right: -48px;

    &-button {
      color: var(--Layer1);
      background-color: var(--TextDefault);
      border-radius: 5px;

      @include is-app-layout-desktop {
        @if $isEnvFeatureTwinlikeStyle {
          &:hover:not([disabled]) {
            color: var(--Layer1);
            background-color: var(--TextDefault);
          }
        } @else {
          &:hover:not([disabled]) {
            color: var(--Layer1);
            background-color: var(--TextDefault);
          }
        }
      }
    }
  }

  &__hamburger {
    display: none;
    margin-right: 4px;
    background-color: var(--Layer1);
    border-radius: 5px;

    &:hover {
      background-color: var(--Highlight);
    }

    @include for-desktop-up {
      display: block;
    }
  }

  &__left {
    display: flex;
    align-items: flex-start;
  }

  &__mask {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: get($zIndex, sidebar-menu) - 1;
    display: block;
    width: 100%;
    min-width: 488px;
    max-width: 1118px;
    content: '';
    background-color: var(--Layer0);
    opacity: 0.7;

    #{$self}--breadcrumb-open & {
      position: absolute;
      top: auto;
      width: 100%;
      height: 100%;
    }
  }
}

.opacity-enter-active {
  transition: opacity 0.25s $animationEaseInOut;
}

.opacity-enter-from,
.opacity-leave-to {
  opacity: 1;
}
</style>
