import type { Ref } from 'vue';
import minBy from 'lodash/minBy';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';

import type { Widget } from '@leon-hub/api-sdk';
import { PostMessageBus } from '@leon-hub/postmessage-bus';

import { useI18nStore } from '@core/i18n';
import { useTheme } from '@core/theme';

import type { SportradarLiveMatchTrackerInitConfig } from 'web/src/modules/framed-app/components/SportradarLiveMatchTrackerFramedWidget/types';
import type { AdditionalQueryStringParameters, IFrameWidgetName } from 'web/src/modules/framed-app/types';
import type { LiveSportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import type { WidgetSizeChangedPayload } from 'web/src/modules/sportline/submodules/widgets/types';
import { requireContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';
import SportradarLiveMatchTrackerPostMessageEvent, {
  PostMessageBusInitiator,
} from 'web/src/modules/framed-app/components/SportradarLiveMatchTrackerFramedWidget/utils/SportradarLiveMatchTrackerPostMessageEvent';
import { sportradarLiveMatchTrackerIframeWidgetName } from 'web/src/modules/framed-app/constants';
import { SportradarFramedWidgetProperties } from 'web/src/modules/sportline/submodules/widgets/enums';
import {
  useSportlineWidgetsStore,
} from 'web/src/modules/sportline/submodules/widgets/store';
import { useFramedWidgetUrl } from 'web/src/modules/widgets/composables/useFramedWidgetUrl';

interface UseSportradarLiveMatchTrackerWidgetProps {
  sportEventDetails: Ref<LiveSportEventDetails>;
  isActive: Ref<boolean>;
}

interface UseSportradarLiveMatchTrackerWidgetEmits {
  onSizeChanged?(size: WidgetSizeChangedPayload): void;
}

interface UseSportradarLiveMatchTrackerWidgetComposable {
  widgetLoaded: Ref<boolean>;
  readyToLoading: Ref<boolean>;
  showFailureMessage: Ref<boolean>;
  iframeWidgetSrc: Ref<string | undefined>;
  iframeName: IFrameWidgetName;
  additionalQueryStringParameters: Ref<AdditionalQueryStringParameters>;
  onIframeMounted(): void;
  releaseMessageBus(): void;
}

export default function useSportradarLiveMatchTrackerWidget(
  props: UseSportradarLiveMatchTrackerWidgetProps,
  emits: UseSportradarLiveMatchTrackerWidgetEmits,
): UseSportradarLiveMatchTrackerWidgetComposable {
  const {
    sportEventDetails,
    isActive,
  } = props;

  const { theme } = useTheme();

  const lang = toRef(useI18nStore(), 'lang');
  const sportradarLiveMatchTrackerConfig = toRef(useSportlineWidgetsStore(), 'sportradarLiveMatchTrackerConfigFinal');

  let postMessageBus: Maybe<Readonly<PostMessageBus>> = null;
  const iframeName = sportradarLiveMatchTrackerIframeWidgetName;

  /** Used for get next iframe window on rerender component, actually change name for current iframe after init */
  const widgetLoaded = ref(false);
  const readyToLoading = ref(false);
  const showFailureMessage = ref(false);

  const matchId = computed<string>(() => sportEventDetails.value.liveWidget?.id || '');
  const widgetProperties = computed<Optional<Widget>>(() => {
    const { liveWidget } = sportEventDetails.value;
    const sportWidgets = (sportradarLiveMatchTrackerConfig.value?.widgets ?? [])
      .filter(({ active, sport }) => active && sport === liveWidget?.sportFamily);
    return sportWidgets.length > 0 ? minBy(sportWidgets, 'order') : sportWidgets[0];
  });
  const widgetSportFamily = computed<Optional<string>>(() => (
    sportEventDetails.value.liveWidget?.sportFamily ?? sportEventDetails.value.sport.representation.family
  ));
  const iframeWidgetSrc = useFramedWidgetUrl(iframeName, theme);
  const additionalQueryStringParameters = computed<AdditionalQueryStringParameters>(() => (
    [
      { [SportradarFramedWidgetProperties.MATCH_ID]: matchId.value },
      { [SportradarFramedWidgetProperties.TYPE]: widgetProperties.value?.name || '' },
      { [SportradarFramedWidgetProperties.NAME]: widgetProperties.value?.name || '' },
      {
        [SportradarFramedWidgetProperties.LANGUAGE]:
        sportradarLiveMatchTrackerConfig.value?.customLang || lang.value,
      },
      { [SportradarFramedWidgetProperties.THEME]: sportradarLiveMatchTrackerConfig.value?.theme || '' },
      {
        [SportradarFramedWidgetProperties.ENCODED_URL]:
          encodeURIComponent(sportradarLiveMatchTrackerConfig.value?.productionUrl || ''),
      },
      { [SportradarFramedWidgetProperties.SPORT_FAMILY]: widgetSportFamily.value },
    ] as AdditionalQueryStringParameters
  ));

  function releaseMessageBus(): void {
    postMessageBus?.dispose();
    postMessageBus = null;
  }

  function onIframeMounted(): void {
    widgetLoaded.value = false;

    releaseMessageBus();

    const iframeContentWindow = requireContentWindowByIFrameName(iframeName);

    postMessageBus = new PostMessageBus({
      target: iframeContentWindow,
      targetOrigin: '*',
      initiator: PostMessageBusInitiator,
    });

    postMessageBus.on(SportradarLiveMatchTrackerPostMessageEvent.mounted, () => {
      const rawConfig = widgetProperties.value?.config || '{}';
      const config = JSON.parse(rawConfig);
      const initOptions: SportradarLiveMatchTrackerInitConfig = {
        id: matchId.value,
        config,
      };

      postMessageBus?.emit(SportradarLiveMatchTrackerPostMessageEvent.init, initOptions);
    });

    postMessageBus.on(SportradarLiveMatchTrackerPostMessageEvent.loaded, () => {
      widgetLoaded.value = true;
    });
    postMessageBus.on(SportradarLiveMatchTrackerPostMessageEvent.widgetLoadError, () => {
      console.error('Live match tracker widget load error');
      widgetLoaded.value = false;
      showFailureMessage.value = true;
    });
    postMessageBus.on(SportradarLiveMatchTrackerPostMessageEvent.widgetLoadTimeout, () => {
      console.error('Live match tracker widget load timeout');
      widgetLoaded.value = false;
      showFailureMessage.value = true;
    });

    postMessageBus.on(SportradarLiveMatchTrackerPostMessageEvent.widgetResized, ({ height }) => {
      emits.onSizeChanged?.({ height });
    });
  }

  watch(isActive, (to: boolean) => {
    if (to) {
      readyToLoading.value = true;
    }
  }, { immediate: true });
  watch(widgetLoaded, (to: boolean) => {
    if (to) {
      showFailureMessage.value = false;
    }
  });

  return {
    widgetLoaded,
    readyToLoading,
    showFailureMessage,
    iframeWidgetSrc,
    iframeName,
    additionalQueryStringParameters,
    onIframeMounted,
    releaseMessageBus,
  };
}
