import { toRef } from 'vue';

import { useNavigationBackButtonStore } from '@core/navigation';

import { SportlineType } from 'web/src/modules/sportline/enums';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import {
  resolveLeaguePageLink,
  resolveLivePageLink,
} from 'web/src/modules/sportline/submodules/navigation/store/utils';

interface UseSportEventDetailsNavigationBackButtonComposable {
  activateBackButton(): void;
}

export function useSportEventDetailsNavigationBackButton(): UseSportEventDetailsNavigationBackButtonComposable {
  const { setNavigationBackLocationGetter } = useNavigationBackButtonStore();
  const sportlineEventDetailsStore = useSportlineEventDetailsStore();
  const sportEventDetails = toRef(sportlineEventDetailsStore, 'sportEventDetails');

  function activateBackButton(): void {
    setNavigationBackLocationGetter(() => {
      if (!sportEventDetails.value) {
        return undefined;
      }

      return sportEventDetails.value.type === SportlineType.Live
        ? resolveLivePageLink(sportEventDetails.value.sport.navigationParameters)
        : resolveLeaguePageLink(sportEventDetails.value.league.navigationParameters);
    });
  }

  return { activateBackButton };
}
