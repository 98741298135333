import type { Ref } from 'vue';
import { ref, watch } from 'vue';

import type { PostMessageBus } from '@leon-hub/postmessage-bus';

import type {
  OddinPostMessageEvent,
} from 'web/src/modules/framed-app/components/oddin/events';
import type {
  OddinReadyStateChangedEvent,
  OddinResizeEvent,
} from 'web/src/modules/framed-app/components/oddin/types';

import type {
  OddinWidgetEmits,
} from '../../types';

interface UseOddinWidgetIFrameAppearanceComposable {
  isWidgetLoaded: Ref<boolean>;
  minHeight: Ref<number | undefined>;
  subscribeToPostMessageBusEvents(postMessageBus: PostMessageBus): void;
}

export function useOddinWidgetIFrameAppearance(
  emit: OddinWidgetEmits,
  WidgetPostMessageEvent: OddinPostMessageEvent,
): UseOddinWidgetIFrameAppearanceComposable {
  const isWidgetLoaded = ref(false);

  const minHeight = ref<number | undefined>(undefined);

  watch(isWidgetLoaded, (value) => {
    emit('change-ready-state', value);

    if (!value) {
      minHeight.value = undefined;
    }
  });

  return {
    isWidgetLoaded,
    minHeight,
    subscribeToPostMessageBusEvents(postMessageBus: PostMessageBus): void {
      isWidgetLoaded.value = false;

      postMessageBus.on(WidgetPostMessageEvent.readyStateChanged, ({ isReady }: OddinReadyStateChangedEvent) => {
        isWidgetLoaded.value = isReady;
      });

      postMessageBus.on(WidgetPostMessageEvent.resize, ({ height }: OddinResizeEvent) => {
        minHeight.value = height;
      });
    },
  };
}
