<script lang="ts" setup>
import { toRef } from 'vue';

import type { BetlinePostMatchStatistics } from 'web/src/modules/sportline/types/rest';
import popperHint from 'web/src/components/Popper/directives/popperHint';
import { TimelineMarkType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
import StatisticTimelineIncidentIcon
  from 'web/src/modules/sportline/submodules/event-details/components/statistic/StatisticTimelineIncidentIcon.vue';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';

import usePostMatchStatisticTimeline from './usePostMatchStatisticTimeline';

interface PostMatchStatisticTimelineProps {
  postMatchStatistic: BetlinePostMatchStatistics;
}

const props = defineProps<PostMatchStatisticTimelineProps>();
const vPopperHint = popperHint;

const {
  teams,
  times,
  onMouseDown,
} = usePostMatchStatisticTimeline({
  postMatchStatistic: toRef(props, 'postMatchStatistic'),
});
// @hint break marks don't have translations (HT, FT, ET...)
</script>

<template>
  <div v-auto-id="'PostMatchStatisticTimeline'"
    v-if="teams"
    :class="$style['post-match-statistic-timeline']"
  >
    <div :class="$style['post-match-statistic-timeline__team']">
      <StatisticTeamLogo
        :class="$style['post-match-statistic-timeline__team-logo']"
        :team="teams[0]"
      />

      <div :class="$style['post-match-statistic-timeline__team-name']">
        {{ teams[0].name }}
      </div>
    </div>

    <div
      ref="container"
      :class="$style['post-match-statistic-timeline__scroll-wrap']"
      @mousedown="onMouseDown"
    >
      <div :class="$style['post-match-statistic-timeline__timeline-container']">
        <div
          v-for="(time, timeNumber) in times"
          :key="timeNumber"
          :class="{
            [$style['post-match-statistic-timeline__time']]: true,
            [$style['post-match-statistic-timeline__time--extra-time']]: time.type === TimelineMarkType.FULL_EXTRA_TIME,
          }"
        >
          <template v-for="(mark, index) in time.marks">
            <template v-if="mark.type === TimelineMarkType.INCIDENTS">
              <div
                :key="`${mark.type}_${index}`"
                :class="[
                  $style['post-match-statistic-timeline__timeline-mark'],
                  $style['post-match-statistic-timeline__timeline-mark--incident'],
                ]"
                :style="{ left: `${mark.position}%` }"
              >
                {{ Math.round(mark.time) }}’

                <template v-if="mark.hostIncidents.length > 0">
                  <div
                    :key="`${mark.type}_${index}_arrow_host`"
                    :class="[
                      $style['post-match-statistic-timeline__timeline-mark-arrow'],
                      $style['post-match-statistic-timeline__timeline-mark-arrow--host'],
                    ]"
                  />
                  <div
                    :class="[
                      $style['post-match-statistic-timeline__timeline-mark-icons'],
                      $style['post-match-statistic-timeline__timeline-mark-icons--host'],
                    ]"
                    :style="{ 'margin-left': `-${8 + (mark.hostIncidents.length - 1) * 6}px` }"
                  >
                    <div
                      v-for="(incident, incidentIndex) in mark.hostIncidents"
                      :key="`${incident.competitorType}_${incident.type}_${incidentIndex}_icon`"
                      v-popper-hint="`${incident.label} ${incident.incomingPlayer.name || ''}`"
                      :class="$style['post-match-statistic-timeline__timeline-mark-icon']"
                      :style="{ 'margin-left': incidentIndex > 0 ? '-4px' : '0' }"
                    >
                      <StatisticTimelineIncidentIcon :incident="incident" />
                    </div>
                  </div>
                </template>

                <template v-if="mark.guestIncidents.length > 0">
                  <div
                    :key="`${mark.type}_${index}_arrow_guest`"
                    :class="[
                      $style['post-match-statistic-timeline__timeline-mark-arrow'],
                      $style['post-match-statistic-timeline__timeline-mark-arrow--guest'],
                    ]"
                  />
                  <div
                    :class="[
                      $style['post-match-statistic-timeline__timeline-mark-icons'],
                      $style['post-match-statistic-timeline__timeline-mark-icons--guest'],
                    ]"
                    :style="{ 'margin-left': `-${8 + (mark.guestIncidents.length - 1) * 6}px` }"
                  >
                    <div
                      v-for="(incident, incidentIndex) in mark.guestIncidents"
                      :key="`${incident.competitorType}_${incident.type}_${incidentIndex}_icon`"
                      v-popper-hint="`${incident.label} ${incident.incomingPlayer.name || ''}`"
                      :class="$style['post-match-statistic-timeline__timeline-mark-icon']"
                      :style="{ 'margin-left': incidentIndex > 0 ? '-4px' : '0' }"
                    >
                      <StatisticTimelineIncidentIcon :incident="incident" />
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </template>

          <div
            v-if="time.type === TimelineMarkType.HALF_TIME && timeNumber + 1 < times.length"
            :key="`${time.type}_${timeNumber}`"
            v-popper-hint="$t('WEB2_MATCH_STATISTIC_HALF_TIME')"
            :class="[
              $style['post-match-statistic-timeline__timeline-mark'],
              $style['post-match-statistic-timeline__timeline-mark--break'],
            ]"
            :style="{ left: '100%' }"
          >
            HT
          </div>
          <div
            v-else-if="time.type === TimelineMarkType.FULL_TIME && timeNumber + 1 < times.length"
            :key="`${time.type}_${timeNumber}`"
            v-popper-hint="$t('WEB2_MATCH_STATISTIC_FULL_TIME')"
            :class="[
              $style['post-match-statistic-timeline__timeline-mark'],
              $style['post-match-statistic-timeline__timeline-mark--break'],
            ]"
            :style="{ left: '100%' }"
          >
            FT
          </div>
          <div
            v-else-if="time.type === TimelineMarkType.FULL_EXTRA_TIME"
            :key="`${time.type}_${timeNumber}`"
            v-popper-hint="$t('WEB2_MATCH_STATISTIC_FULL_EXTRA_TIME')"
            :class="[
              $style['post-match-statistic-timeline__timeline-mark'],
              $style['post-match-statistic-timeline__timeline-mark--break'],
            ]"
            :style="{ left: '50%' }"
          >
            ET
          </div>
        </div>
      </div>
    </div>

    <div :class="$style['post-match-statistic-timeline__team']">
      <StatisticTeamLogo
        :class="$style['post-match-statistic-timeline__team-logo']"
        :team="teams[1]"
      />

      <div :class="$style['post-match-statistic-timeline__team-name']">
        {{ teams[1].name }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.post-match-statistic-timeline {
  padding: 16px 8px;
  background-color: var(--Layer2);
  border-radius: 5px;

  &__team {
    display: flex;
    justify-content: flex-start;
  }

  &__team-logo {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  &__team-name {
    @include medium\14\16\025;

    line-height: 20px;
    color: var(--TextDefault);
  }

  &__scroll-wrap {
    width: 100%;
    overflow: -moz-scrollbars-none;
    overflow-x: auto;
    user-select: none;
    background-color: transparent;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__timeline-container {
    display: flex;
    justify-content: flex-end;
    min-width: #{$phoneMax};
    height: 72px;
    margin: 10px 0;
  }

  &__time {
    position: relative;
    width: 50%;
    height: 16px;
    margin: auto 0;
    background-color: var(--BrandDefault);
    border-radius: 8px;

    &--extra-time {
      width: 25%;
    }
  }

  &__timeline-mark {
    position: absolute;
    width: 24px;
    height: 24px;
    margin-top: -4px;
    margin-left: -12px;
    color: var(--White);
    text-align: center;

    &[title] {
      cursor: pointer;
    }

    &--break {
      @include medium\12\16;
      @include z-index(absolute-item);

      line-height: 24px;
      color: var(--TextDefault);
      background-color: var(--Layer2);
      border: 1px solid var(--BrandDefault);
      border-radius: 20px;
    }

    &--incident {
      @include medium\14\16\025;

      line-height: 24px;
    }
  }

  &__timeline-mark-arrow {
    position: absolute;
    left: 50%;
    width: 2px;
    height: 8px;
    margin: 0 0 0 -1px;
    background-color: var(--BrandDefault);

    &--host {
      bottom: 20px;
    }

    &--guest {
      top: 20px;
    }
  }

  &__timeline-mark-icons {
    position: absolute;
    left: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;

    &--host {
      bottom: 32px;
    }

    &--guest {
      top: 32px;
    }
  }

  &__timeline-mark-icon {
    background-color: var(--Layer2);

    &[title] {
      cursor: pointer;
    }
  }
}
</style>
