import { safeParseInt } from '@leon-hub/utils';

export function valueToPx(rawValue?: string | number): Optional<string> {
  if (!rawValue) {
    return undefined;
  }

  const value = rawValue.toString();

  return /px|%/g.test(value) ? value : `${value}px`;
}

export function pxToValue(rawValue?: Optional<string>): Optional<number> {
  if (!rawValue) {
    return undefined;
  }

  return safeParseInt(rawValue) ?? undefined;
}
