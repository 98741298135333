import { PostMessageBus } from '@leon-hub/postmessage-bus';

import { requireContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';

interface UseOddinWidgetPostMessageBusComposable {
  createPostMessageBus(): PostMessageBus;
  disposePostMessageBus(): void;
  getPostMessageBus(): PostMessageBus | null;
}

export function useOddinWidgetPostMessageBus(initiator: string, iframeName: string): UseOddinWidgetPostMessageBusComposable {
  let postMessageBus: PostMessageBus | null = null;

  function disposePostMessageBus(): void {
    postMessageBus?.dispose();
    postMessageBus = null;
  }

  return {
    disposePostMessageBus,
    createPostMessageBus(): PostMessageBus {
      disposePostMessageBus();

      const iframeContentWindow = requireContentWindowByIFrameName(iframeName);

      postMessageBus = new PostMessageBus({
        target: iframeContentWindow,
        targetOrigin: '*',
        initiator,
      });

      return postMessageBus;
    },
    getPostMessageBus(): PostMessageBus | null {
      return postMessageBus;
    },
  };
}
