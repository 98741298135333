import type { MaybeRef, Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { Sport, SportlineEventWidgetInfo } from 'web/src/modules/sportline/types';
import { sportlineWidgetTypeBetgenius } from 'web/src/modules/sportline/constants/widgets';
import { SportFamily } from 'web/src/modules/sportline/enums';
import { useSportlineEventLiveWidget } from 'web/src/modules/sportline/submodules/event-details/composables/widget';
import { HeadlineExtendedHeight } from 'web/src/modules/sportline/submodules/event-details/enums';
import { isSportFamilyEquals } from 'web/src/modules/sportline/utils';

// eslint-disable-next-line ts/no-empty-object-type
type EmptySportlineEventData = {} | undefined;

interface UseHeadlineLayoutExtendedHeightProps {
  sport?: MaybeRef<Maybe<Sport | undefined>>;
  sportlineEvent?: MaybeRef<Maybe<SportlineEventWidgetInfo | EmptySportlineEventData>>;
}

interface UseHeadlineLayoutExtendedHeightComposable {
  extendedHeight: Ref<Maybe<HeadlineExtendedHeight>>;
}

export function useHeadlineLayoutExtendedHeight(
  props: UseHeadlineLayoutExtendedHeightProps,
): UseHeadlineLayoutExtendedHeightComposable {
  const sport: Ref<Maybe<Sport | undefined>> = toRef(props.sport ?? undefined);
  const sportlineEvent: Ref<Maybe<SportlineEventWidgetInfo | EmptySportlineEventData>> = toRef(props.sportlineEvent ?? undefined);

  const { liveWidget } = useSportlineEventLiveWidget({ sportlineEvent });

  const extendedHeight = computed<Maybe<HeadlineExtendedHeight>>(() => {
    if (liveWidget.value?.type === sportlineWidgetTypeBetgenius) {
      return HeadlineExtendedHeight.BetGeniusWidget;
    }

    if (isSportFamilyEquals(sport.value?.representation.family, SportFamily.VirtualCricket)) {
      return HeadlineExtendedHeight.VirtualCricketWidget;
    }

    return null;
  });

  return { extendedHeight };
}
