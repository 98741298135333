import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { HeadlineSlideName } from 'web/src/modules/sportline/submodules/event-details/enums';

interface UseHeadlineInplayComponentShowStateProps {
  activeSlideId: Ref<Optional<HeadlineSlideName>>;
  isStreamAvailable: Ref<boolean>;
  isLiveWidgetAvailable: Ref<boolean>;
  doForceShowInplayComponent?: Ref<boolean>;
}

interface UseHeadlineInplayComponentShowStateComposable {
  isLiveWidgetShown: Ref<boolean>;
  isStreamShown: Ref<boolean>;
}

export function useHeadlineInplayComponentShowState(
  props: UseHeadlineInplayComponentShowStateProps,
): UseHeadlineInplayComponentShowStateComposable {
  const {
    activeSlideId,
    isLiveWidgetAvailable,
    isStreamAvailable,
  } = props;

  const doForceShowInplayComponent = props.doForceShowInplayComponent ?? ref(false);

  const doShowInfo = computed(() => !activeSlideId.value || activeSlideId.value === HeadlineSlideName.Info);
  const internalActiveSlideId = computed<Optional<HeadlineSlideName>>(() => (
    doShowInfo.value && doForceShowInplayComponent.value
      ? HeadlineSlideName.LiveStream
      : activeSlideId.value
  ));
  const doShowStream = computed<boolean>(() => (internalActiveSlideId.value === HeadlineSlideName.LiveStream));
  const doShowLiveWidget = computed<boolean>(() => (internalActiveSlideId.value === HeadlineSlideName.LiveWidget));

  const isStreamShown = computed<boolean>(() => {
    if (!isStreamAvailable.value) {
      return false;
    }

    // show anyway if stream is available but widget is not
    if (doForceShowInplayComponent.value && !isLiveWidgetAvailable.value) {
      return true;
    }

    return doShowStream.value;
  });
  const isLiveWidgetShown = computed<boolean>(() => {
    if (!isLiveWidgetAvailable.value) {
      return false;
    }

    // show anyway if widget is available but stream is not
    if (doForceShowInplayComponent.value && !isStreamAvailable.value) {
      return true;
    }

    return doShowLiveWidget.value;
  });

  return {
    isLiveWidgetShown,
    isStreamShown,
  };
}
