<script setup lang="ts">
import { computed, toRef } from 'vue';

import { useSportlineEventDetailsParts } from 'web/src/modules/sportline/submodules/event-details/composables/event-details';
import { useHeadlineLayoutExtendedHeight } from 'web/src/modules/sportline/submodules/event-details/composables/headline';

import type { HeadlineWideLayoutProps, HeadlineWideLayoutSlots } from './types';
import { useCustomWidgetHeight, useHeadlineDefaultLayout, useHeadlineWideLayout } from './composables';

const props = defineProps<HeadlineWideLayoutProps>();
defineSlots<HeadlineWideLayoutSlots>();

const sportlineEvent = toRef(props, 'sportEventDetails');
const hasPrimaryMarket = toRef(props, 'hasPrimaryMarket', false);
const hasInplayComponent = toRef(props, 'hasInplayComponent', false);

const {
  backgroundLineType,
  headlineLayoutName,
} = useHeadlineWideLayout({
  autoLayoutAllowed: false,
  sportlineEvent,
  hasPrimaryMarket,
  hasInplayComponent,
});
const layoutClassName = computed(() => `headline-layout--${backgroundLineType.value}-${headlineLayoutName.value}`);

const { sport } = useSportlineEventDetailsParts({ sportEventDetails: sportlineEvent });
const { backgroundColor } = useHeadlineDefaultLayout({ sport });
const { extendedHeight } = useHeadlineLayoutExtendedHeight({ sport, sportlineEvent });

const {
  customWidgetHeight,
  setHeight,
} = useCustomWidgetHeight();
</script>

<template>
  <div v-auto-id="'HeadlineDefaultWideLayout'"
    :class="{
      [$style['headline-layout']]: true,
      [$style['headline-layout--default']]: !backgroundColor,
      [$style['headline-layout--colored']]: !!backgroundColor,
      [$style[layoutClassName]]: !!layoutClassName,
    }"
  >
    <div
      :class="{
        [$style['headline-layout__info']]: true,
        [$style['headline-layout__info--full-width']]: !hasInplayComponent,
      }"
      :style="{ 'background-color': backgroundColor ?? undefined }"
    >
      <slot
        name="info"
        :background-color="backgroundColor"
      />
    </div>

    <div
      v-if="hasInplayComponent"
      :class="$style['inplay-component__holder']"
      :style="{ backgroundColor: backgroundColor ?? undefined }"
    >
      <div
        :class="{
          [$style['inplay-component']]: true,
          [$style[`inplay-component--extended-${extendedHeight}`]]: !!extendedHeight,
        }"
        :style="{ minHeight: customWidgetHeight }"
      >
        <slot
          name="inplay-component"
          :set-height="setHeight"
          :background-color="backgroundColor"
        />
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/submodules/event-details/views/headline-layout/sportline-page-headline-layout';
@include headline-layout-colors-for-common-sport('.headline-layout');
@include wide-headline-layout('.headline-layout');

.headline-layout {
  &--live-default {
    min-height: 234px;
  }

  &--live-low {
    min-height: 172px;
  }

  &--prematch-default {
    min-height: 172px;
  }

  &--prematch--large {
    min-height: 234px;
  }

  &--outright-default {
    min-height: 181px;
  }

  &--outright--large {
    min-height: 234px;
  }
}

.inplay-component {
  position: relative;
  width: 325px;
  height: 200px;
  min-height: 100%;
  overflow: hidden;
  border-radius: 5px;

  &--extended {
    &-virtual-cricket {
      height: 290px;
    }

    &-bet-genius {
      height: 350px;
    }
  }

  &__holder {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 234px;
    background-color: var(--Layer1);
    border-radius: 5px;
  }
}
</style>
