import type { Ref } from 'vue';
import { computed } from 'vue';

import { useI18nLocale } from '@core/i18n';

// composables
import { useAvailableHeadlineSlides } from 'web/src/modules/sportline/submodules/event-details/composables/headline/useAvailableHeadlineSlides';
// types
import type {
  SportEventDetailsHeadlineWidget,
  SportEventDetailsSlideId,
  SportEventWidgetConfig,
  SportlineEventDetails,
} from 'web/src/modules/sportline/submodules/event-details/types';
import type {
  BetgeniusWidgetProps,
  LSportsWidgetProps,
  SportradarLiveMatchTrackerWidgetProps,
} from 'web/src/modules/sportline/submodules/widgets/types';
import type { SportlineWidgetType } from 'web/src/modules/sportline/types';
// enums
import { SportlineType } from 'web/src/modules/sportline/enums';
import { HeadlineSlideName } from 'web/src/modules/sportline/submodules/event-details/enums';
// constants
import {
  sportlineWidgetTypeBetgenius,
  sportlineWidgetTypeLsports,
  sportlineWidgetTypeSportradar,
} from 'web/src/modules/sportline/constants/widgets';

export interface UseBaseSportEventDetailsHeadlineProps {
  defaultSlideId: Ref<Optional<HeadlineSlideName>>;
  sportEventDetails: Ref<Maybe<SportlineEventDetails>>;
  isStreamAvailable: Ref<boolean>;
  widgetConfig: Ref<Maybe<SportEventWidgetConfig>>;
  liveWidgetType: Ref<Optional<SportlineWidgetType>>;
}

export interface BaseSportEventDetailsHeadlineComposable {
  activeSlideId: Ref<Optional<HeadlineSlideName>>;
  isLiveWidgetAvailable: Ref<boolean>;
  widgetProperties: Ref<SportEventDetailsHeadlineWidget | undefined>;
  switcherSlidesIds: Ref<SportEventDetailsSlideId[]>;
}

export function useBaseSportEventDetailsHeadline(
  props: UseBaseSportEventDetailsHeadlineProps,
): BaseSportEventDetailsHeadlineComposable {
  const {
    defaultSlideId,
    sportEventDetails,
    isStreamAvailable,
    widgetConfig,
    liveWidgetType,
  } = props;

  const { locale } = useI18nLocale();

  const widgetProperties = computed<SportEventDetailsHeadlineWidget | undefined>((): SportEventDetailsHeadlineWidget | undefined => {
    const sportlineEvent = sportEventDetails.value;

    if (!sportlineEvent) {
      return undefined;
    }

    switch (liveWidgetType.value) {
      case sportlineWidgetTypeBetgenius: {
        if (sportlineEvent.type !== SportlineType.Live) {
          return undefined;
        }

        if (!sportlineEvent.liveWidget) {
          return undefined;
        }

        const widgetProps: BetgeniusWidgetProps = {
          isFloatingModeEnabled: !!widgetConfig.value?.sportRadarWidgetFloatingEnabled,
          liveWidget: sportlineEvent.liveWidget,
          locale: locale.value,
        };

        return {
          type: sportlineWidgetTypeBetgenius,
          props: widgetProps,
        };
      }

      case sportlineWidgetTypeLsports: {
        if (sportlineEvent.type !== SportlineType.Live) {
          return undefined;
        }

        const widgetProps: LSportsWidgetProps = {
          isFloatingModeEnabled: !!widgetConfig.value?.sportRadarWidgetFloatingEnabled,
          sportEventDetails: sportlineEvent,
        };

        return {
          type: sportlineWidgetTypeLsports,
          props: widgetProps,
        };
      }

      case sportlineWidgetTypeSportradar: {
        if (sportlineEvent.type !== SportlineType.Live) {
          return undefined;
        }

        const widgetProps: SportradarLiveMatchTrackerWidgetProps = {
          isFloatingModeEnabled: !!widgetConfig.value?.sportRadarWidgetFloatingEnabled,
          sportEventDetails: sportlineEvent,
        };

        return {
          type: sportlineWidgetTypeSportradar,
          props: widgetProps,
        };
      }

      default:
        return undefined;
    }
  });

  const isLiveWidgetAvailable = computed(() => !!widgetProperties.value);

  const {
    switcherSlidesIds,
  } = useAvailableHeadlineSlides({
    sportlineEvent: sportEventDetails,
    isLiveWidgetAvailable,
    isStreamAvailable,
  });

  const activeSlideId = computed<Optional<HeadlineSlideName>>(() => (
    switcherSlidesIds.value.some((item) => item.id === defaultSlideId.value)
      ? defaultSlideId.value
      : HeadlineSlideName.Info
  ));

  return {
    activeSlideId,
    switcherSlidesIds,
    isLiveWidgetAvailable,
    widgetProperties,
  };
}
