import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter, AsyncAbstractPrefetchResult } from '@core/router';

// composables
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
// utils
import { GetEventSubscriptionAction } from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter/enums';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';

import BaseSportEventDetailsPageNavigationService from './BaseSportEventDetailsPageNavigationService';

/**
 * Handle navigation for prerender (search bots)
 * Check missing event and redirect
 */
export default class PrerenderSportEventDetailsPageNavigationService
  extends BaseSportEventDetailsPageNavigationService {
  async prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
  ): AsyncAbstractPrefetchResult {
    const superResult = await super.prefetch(router, to);
    if (superResult !== undefined) {
      return (superResult as RouteLocationRaw);
    }

    void this.loadPageData(router, to);
  }

  /* Method for tests */
  getIsReadyToDisplay(): boolean {
    const detailsStore = useSportlineEventDetailsStore();
    return detailsStore.isReadyToDisplayContent;
  }

  protected async loadPageData(
    router: AppVueRouter,
    to: RouteLocationNormalized,
  ): AsyncAbstractPrefetchResult {
    const detailsStore = useSportlineEventDetailsStore();

    const { sportEventId } = getSportlinePageNavigationParameters(to);

    if (sportEventId) {
      // default navigation behaviour registration
      detailsStore.createOnInitBehaviour(sportEventId, {});
    }

    await detailsStore.initialRequests();

    if (detailsStore.sportEventDetails) {
      this.onInitBehaviours?.[GetEventSubscriptionAction.Finish]?.();
      return;
    }

    const statisticStore = useSportlineEventStatisticStore();

    if (statisticStore.hasPostMatchStatistics) {
      this.onInitBehaviours?.[GetEventSubscriptionAction.Finish]?.();
      return;
    }

    this.onInitBehaviours?.[GetEventSubscriptionAction.Redirect]?.();
    // use direct redirect to home but not RedirectByBreadcrumbs (made in LEONWEB-6332)
    return router.resolve301location({ name: RouteName.HOME });
  }
}
