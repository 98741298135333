import type { Ref } from 'vue';
import { toRef } from 'vue';

import type { MatchProgressTimeDirection } from 'web/src/modules/sportline/enums';
import type { LiveSportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import type {
  Score,
  SportEventMatchProgressTime,
} from 'web/src/modules/sportline/types';
import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
import { useLiveMatchProgress } from 'web/src/modules/sportline/composables/match-progress';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';

interface UseEventDetailsMiniScoreBoardProps {
  sportEvent: Ref<LiveSportEventDetails>;
}

interface UseEventDetailsMiniScoreBoardComposable {
  isTimeReversedIndicatorAvailable: Ref<boolean>;

  totalScore: Ref<Score<string>>;
  gameScoreProgress: Ref<Optional<string>>;
  isStaticLiveProgress: Ref<boolean>;
  isTimeReversed: Ref<boolean>;
  staticReversedTimeProgressLabel: Ref<Optional<string>>;
  staticDirectTimeProgressLabel: Ref<Optional<string>>;
  additionalTime: Ref<Optional<SportEventMatchProgressTime>>;
  mainTimeTotalSeconds: Ref<number>;
  mainTimeProgress: Ref<string>;
  mainTimeProgressShortFormat: Ref<string>;
  additionalTimeTotalSeconds: Ref<number>;
  additionalTimeProgress: Ref<string>;
  stage: Ref<string>;
  scoreDetailsLine: Ref<string | undefined>;
  mainTimeDirectiveDirection: Ref<MatchProgressTimeDirection>;
  additionalTimeDirectiveDirection: Ref<MatchProgressTimeDirection>;
}

export function useEventDetailsMiniScoreBoard(
  props: UseEventDetailsMiniScoreBoardProps,
): UseEventDetailsMiniScoreBoardComposable {
  const { sportEvent } = props;

  const sportlineSettingsStore = useSportlineSettingsStore();
  const isTimeReversedIndicatorAvailable = toRef(sportlineSettingsStore, 'isTimeReversedIndicatorAvailable');

  const {
    matchProgress,
  } = useLiveMatchProgress({ sportEvent });
  const {
    totalScore,
    stage,
    gameScoreProgress,
    isStaticLiveProgress,
    isTimeReversed,
    staticReversedTimeProgressLabel,
    staticDirectTimeProgressLabel,
    additionalTime,
    mainTimeTotalSeconds,
    mainTimeProgress,
    mainTimeProgressShortFormat,
    additionalTimeTotalSeconds,
    additionalTimeProgress,
    scoreDetailsLine,
    mainTimeDirectiveDirection,
    additionalTimeDirectiveDirection,
  } = useLiveStatus(matchProgress);

  return {
    isTimeReversedIndicatorAvailable,
    totalScore,
    stage,
    gameScoreProgress,
    isStaticLiveProgress,
    isTimeReversed,
    staticReversedTimeProgressLabel,
    staticDirectTimeProgressLabel,
    additionalTime,
    mainTimeTotalSeconds,
    mainTimeProgress,
    mainTimeProgressShortFormat,
    additionalTimeTotalSeconds,
    additionalTimeProgress,
    scoreDetailsLine,
    mainTimeDirectiveDirection,
    additionalTimeDirectiveDirection,
  };
}
