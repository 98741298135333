import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { DateTime, TimeFormats } from '@leon-hub/utils';

import type {
  BetlineMatchStatisticsMatch,
  BetlineMatchStatisticsTeam,
} from 'web/src/modules/sportline/types/rest';
import { SportFamily } from 'web/src/modules/sportline/enums';
import { BetlineStatisticScoresType } from 'web/src/modules/sportline/enums/rest';
import { splitScoreValue } from 'web/src/modules/sportline/submodules/core-statistic/utils';

interface UseSportEventMatchStatisticProps {
  match: Ref<BetlineMatchStatisticsMatch>;
  sportFamily: Ref<Maybe<string>>;
}

interface UseSportEventMatchStatisticComposable {
  isLive: Ref<boolean>;
  host: Ref<Maybe<BetlineMatchStatisticsTeam>>;
  guest: Ref<Maybe<BetlineMatchStatisticsTeam>>;
  isHostWinner: Ref<boolean>;
  isGuestWinner: Ref<boolean>;
  resultScore: Ref<string[]>;
  setsScore: Ref<string[][]>;
  date: Ref<string>;
}

export function useSportEventMatchStatistic(
  props: UseSportEventMatchStatisticProps,
): UseSportEventMatchStatisticComposable {
  const { match, sportFamily } = props;

  // @TODO compare with current open details
  const isLive = ref<boolean>(false);
  const host = computed<Maybe<BetlineMatchStatisticsTeam>>(() => (match.value.teams[0] || null));
  const guest = computed<Maybe<BetlineMatchStatisticsTeam>>(() => (match.value.teams[1] || null));
  const date = computed<string>(() => {
    const timestamp = match.value.matchDate;
    return timestamp
      ? DateTime.formatTimeStamp(timestamp, TimeFormats.dayMonthShortYear)
      : '';
  });
  const isHostWinner = computed<boolean>(() => !!host.value && host.value.type === match.value.winner);
  const isGuestWinner = computed<boolean>(() => !!guest.value && guest.value.type === match.value.winner);

  const resultScore = computed<string[]>(() => {
    if (sportFamily.value === SportFamily.Tennis) {
      const totalSetsScoreValue = (match.value.resultScores || [])
        .find((scores) => scores.type === BetlineStatisticScoresType.Sets);

      if (totalSetsScoreValue) {
        return splitScoreValue(totalSetsScoreValue.value, '0:0');
      }

      // fallback to default result score value
    }

    return splitScoreValue(match.value.resultScore, '0:0');
  });
  const setsScore = computed<string[][]>(() => {
    if (sportFamily.value === SportFamily.Tennis) {
      const resultScores = (match.value.resultScores || [])
        .filter((scores) => scores.type !== BetlineStatisticScoresType.Sets
          && scores.type !== BetlineStatisticScoresType.Winner);

      return Array.from({
        length: process.env.VUE_APP_LAYOUT_DESKTOP ? 5 : 1,
      }, (_, index) => (splitScoreValue(resultScores[index]?.value, ':')));
    }

    return [];
  });

  return {
    isLive,
    host,
    guest,
    isHostWinner,
    isGuestWinner,
    date,
    resultScore,
    setsScore,
  };
}
