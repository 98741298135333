<script setup lang="ts">
import { computed, toRef } from 'vue';

import type { BetlineMatchStatisticsTeam } from 'web/src/modules/sportline/types/rest';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';

interface MatchesStatisticCompetitorsProps {
  teams: [BetlineMatchStatisticsTeam, BetlineMatchStatisticsTeam];
}

const props = defineProps<MatchesStatisticCompetitorsProps>();

const teams = toRef(props, 'teams');

const host = computed<BetlineMatchStatisticsTeam>(() => teams.value[0]);
const guest = computed<BetlineMatchStatisticsTeam>(() => teams.value[1]);
</script>

<template>
  <div v-auto-id="'MatchesStatisticCompetitors'" :class="$style['matches-statistic-competitors']">
    <div :class="$style['matches-statistic-competitor']">
      <StatisticTeamLogo
        :class="$style['matches-statistic-competitor-logo']"
        :team="host"
      />

      <span :class="$style['matches-statistic-competitor-name']">
        {{ host.name }}
      </span>
    </div>

    <div
      :class="[
        $style['matches-statistic-competitor'],
        $style['matches-statistic-competitor--guest'],
      ]"
    >
      <span :class="$style['matches-statistic-competitor-name']">
        {{ guest.name }}
      </span>

      <StatisticTeamLogo
        :class="$style['matches-statistic-competitor-logo']"
        :team="guest"
      />
    </div>
  </div>
</template>

<style module lang="scss">
.matches-statistic-competitors {
  display: flex;
  justify-content: space-between;
  padding: 14px 4px 18px;
}

.matches-statistic-competitor {
  @include medium\14\16\025\caps;

  display: flex;
  justify-content: center;
  color: var(--TextDefault);
  text-transform: none;

  &--guest {
    text-align: right;
  }
}

.matches-statistic-competitor-logo {
  width: 20px;
  height: 20px;
  margin: auto 0;
}

.matches-statistic-competitor-name {
  line-height: 20px;

  &:last-child {
    padding-left: 8px;
  }

  &:first-child {
    padding-right: 8px;
  }
}
</style>
