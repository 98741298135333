<script lang="ts" setup>
import { toRef } from 'vue';

import { VLazyIframe as VIframe } from 'web/src/components/Iframe';

import type {
  OddinWidgetEmits,
  OddinWidgetProps,
} from '../types';
import { useOddinPrematchWidget } from './useOddinPrematchWidget';

const props = defineProps<OddinWidgetProps>();
const emit = defineEmits<OddinWidgetEmits>();

const iframeSrc = toRef(() => props.iframeSrc);

const {
  minHeight,
  iframeName,
  iframedAppSrc,
  additionalQueryStringParameters,
  onIframeMounted,
  onIframeUnmounted,
} = useOddinPrematchWidget({ iframeSrc }, emit);
</script>

<template>
  <div v-auto-id="'OddinPrematchWidget'"
    v-data-test="{ el: 'oddin-prematch-widget', src: iframeSrc }"
    :class="$style['oddin-prematch-widget']"
    :style="{ height: minHeight ? `${minHeight + 6}px` : undefined }"
  >
    <VIframe
      v-if="iframedAppSrc"
      :key="iframedAppSrc"
      full-height
      full-width
      :src="iframedAppSrc"
      :name="iframeName"
      :additional-query-string-parameters="additionalQueryStringParameters"
      @vue:mounted="onIframeMounted"
      @vue:unmounted="onIframeUnmounted"
    />
  </div>
</template>

<style module lang="scss">
.oddin-prematch-widget {
  display: flex;
  align-items: center;
}
</style>
