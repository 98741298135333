<script setup lang="ts">
import { computed, toRef } from 'vue';

import type {
  SportElement,
  SportlineEventElement,
} from 'web/src/modules/sportline/types';
import SportEventsOutrightHeadline from 'web/src/modules/sportline/components/SportlineOutrightHeadline/SportlineOutrightHeadline.vue';
import { createSportlineFragmentBasisKey } from 'web/src/modules/sportline/composables/list/utils';
import { extendedEventBlockBreadcrumbs } from 'web/src/modules/sportline/submodules/extended-event';
import SportlineRegionBlock from 'web/src/modules/sportline/views/RegionBlock/SportlineRegionBlock.vue';

import { useBreadcrumbsRelatedEvents } from './useBreadcrumbsRelatedEvents';

interface BreadcrumbsRelatedEventsProps {
  sportElement: SportElement;
  relatedEvents: SportlineEventElement[];
  activeEventId?: Maybe<string>;
  isMarketTypesSelectionEnabled?: boolean;
}

interface BreadcrumbsRelatedEventsEmits {
  (e: 'click-event'): void;
}

const props = defineProps<BreadcrumbsRelatedEventsProps>();
const emit = defineEmits<BreadcrumbsRelatedEventsEmits>();

const sportElement = toRef(props, 'sportElement');
const relatedEvents = toRef(props, 'relatedEvents');
const activeEventId = toRef(props, 'activeEventId', null);
const isMarketTypesSelectionEnabled = toRef(props, 'isMarketTypesSelectionEnabled', false);

const {
  sport,
  league,
  regionElement,
  doShowOutrightEvents,
} = useBreadcrumbsRelatedEvents({
  relatedEvents,
  sportElement,
  isMarketTypesSelectionEnabled,
});

const sportId = computed(() => sport.value.id);
const basisKey = createSportlineFragmentBasisKey({ sportId });
</script>

<template>
  <div v-auto-id="'BreadcrumbsRelatedEvents'"
    :key="`details-related-league-${league.id}`"
    :class="$style['sport-event-related-list']"
  >
    <SportlineRegionBlock
      :sport="sport"
      :region-element="regionElement"
      :active-event-id="activeEventId"
      :can-add-to-favorite="!!'1'"
      :can-add-outrights-to-favorite="!!'1'"
      :extended-block-type="extendedEventBlockBreadcrumbs"
      :basis-key="basisKey"
      @click-event="emit('click-event')"
    />

    <template v-if="doShowOutrightEvents">
      <SportEventsOutrightHeadline />

      <SportlineRegionBlock
        :sport="sport"
        :region-element="regionElement"
        :active-event-id="activeEventId"
        is-hide-league-info
        is-column-names-display-disabled
        is-display-main-events-disabled
        is-display-outright-headline-disabled
        is-display-outright-events-enabled
        :can-add-to-favorite="!!'1'"
        :can-add-outrights-to-favorite="!!'1'"
        :extended-block-type="extendedEventBlockBreadcrumbs"
        :basis-key="basisKey"
        @click-event="emit('click-event')"
      />
    </template>
  </div>
</template>

<style module lang="scss">
.sport-event-related-list {
  background-color: var(--Layer0);
}
</style>
