import type { Ref } from 'vue';
import { ref } from 'vue';

import type { SetHeadlineHeightValue } from '../types';
import { valueToPx } from '../utils';

interface UseCustomWidgetHeightComposable {
  customWidgetHeight: Ref<string | undefined>;
  setHeight(value?: SetHeadlineHeightValue): void;
}

export function useCustomWidgetHeight(): UseCustomWidgetHeightComposable {
  const customWidgetHeight = ref<string | undefined>(undefined);

  return {
    customWidgetHeight,
    setHeight(value?: SetHeadlineHeightValue): void {
      customWidgetHeight.value = valueToPx(value);
    },
  };
}
