import type { Ref } from 'vue';
import { computed } from 'vue';

import type { OddinAdditionalQueryStringParameters } from 'web/src/modules/framed-app/components/oddin/types';
import { encodedIFrameUrlQueryParameter } from 'web/src/modules/framed-app/components/oddin/constants';

export function createAdditionalQueryStringParameters(iframeSrc: Ref<string>): Ref<OddinAdditionalQueryStringParameters> {
  return computed<OddinAdditionalQueryStringParameters>(() => ([
    { [encodedIFrameUrlQueryParameter]: encodeURIComponent(iframeSrc.value) },
  ]));
}
