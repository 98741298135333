<script lang="ts" setup>
import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SportEventDetailsHeadlineWidget } from 'web/src/modules/sportline/submodules/event-details/types';
import type {
  BaseSportlineEventDetailsWidgetEmits,
  BaseSportlineEventDetailsWidgetProps,
} from 'web/src/modules/sportline/submodules/widgets/types';
import type { SportlineBackgroundColor } from 'web/src/modules/sportline/types';
import {
  isBetgeniusDetailsHeadlineWidget,
  isLSportsDetailsHeadlineWidget,
  isSportradarLMTDetailsHeadlineWidget,
} from 'web/src/modules/sportline/submodules/event-details/guards/headline/widgets';

import BetgeniusWidget from './BetgeniusWidget.vue';
import LSportsWidget from './LSportsWidget.vue';
import SportradarLiveMatchTrackerWidget from './SportradarLiveMatchTrackerWidget.vue';

interface TrackerWidgetProps extends BaseSportlineEventDetailsWidgetProps {
  widget: SportEventDetailsHeadlineWidget;
  backgroundColor?: Maybe<SportlineBackgroundColor>;
}

const props = defineProps<TrackerWidgetProps>();
const emit = defineEmits<BaseSportlineEventDetailsWidgetEmits>();

function getActualProp<Key extends keyof BaseSportlineEventDetailsWidgetProps>(
  key: Key,
  defaultValue: BaseSportlineEventDetailsWidgetProps[Key],
): Exclude<Ref<BaseSportlineEventDetailsWidgetProps[Key]>, undefined> {
  return computed(() => props.widget.props[key] ?? props[key] ?? defaultValue);
}

const isActive = getActualProp('isActive', false);
const isFloatingModeAllowed = getActualProp('isFloatingModeAllowed', false);
const isFloatingModeEnabled = getActualProp('isFloatingModeEnabled', false);
const aspectRatio = getActualProp('aspectRatio', undefined);
</script>

<template>
  <SportradarLiveMatchTrackerWidget
    v-if="isSportradarLMTDetailsHeadlineWidget(widget)"
    :sport-event-details="widget.props.sportEventDetails"
    :is-floating-mode-enabled="isFloatingModeEnabled"
    :is-active="isActive"
    :is-floating-mode-allowed="isFloatingModeAllowed"
    :aspect-ratio="aspectRatio"
    :background-color="backgroundColor"
    @size-changed="emit('size-changed', $event)"
  />
  <LSportsWidget
    v-else-if="isLSportsDetailsHeadlineWidget(widget)"
    :sport-event-details="widget.props.sportEventDetails"
    :is-floating-mode-enabled="isFloatingModeEnabled"
    :is-active="isActive"
    :is-floating-mode-allowed="isFloatingModeAllowed"
    :aspect-ratio="aspectRatio"
  />
  <BetgeniusWidget
    v-else-if="isBetgeniusDetailsHeadlineWidget(widget)"
    :live-widget="widget.props.liveWidget"
    :locale="widget.props.locale"
    :is-floating-mode-enabled="isFloatingModeEnabled"
    :is-active="isActive"
    :is-floating-mode-allowed="isFloatingModeAllowed"
    :aspect-ratio="aspectRatio"
  />
</template>
