import type { Ref } from 'vue';
import { computed } from 'vue';

import type { HeadlineSlideName } from 'web/src/modules/sportline/submodules/event-details/enums';
import type { SportEventWidgetConfig } from 'web/src/modules/sportline/submodules/event-details/types';

import { useHeadlineInplayComponentShowState } from './composables';

interface UseWideSportlineEventDetailsHeadlineProps {
  activeSlideId: Ref<Optional<HeadlineSlideName>>;
  isStreamAvailable: Ref<boolean>;
  isLiveWidgetAvailable: Ref<boolean>;
  doForceShowInplayComponent: Ref<boolean>;
  widgetConfig: Ref<Maybe<SportEventWidgetConfig>>;
}

interface UseWideSportlineEventDetailsHeadlineComposable {
  isStreamFloatingEnabled: Ref<boolean>;
  isLiveWidgetFloatingEnabled: Ref<boolean>;
  isLiveWidgetShown: Ref<boolean>;
  isStreamShown: Ref<boolean>;
}

export function useWideSportlineEventDetailsHeadline(
  props: UseWideSportlineEventDetailsHeadlineProps,
): UseWideSportlineEventDetailsHeadlineComposable {
  const {
    doForceShowInplayComponent,
    activeSlideId,
    isLiveWidgetAvailable,
    isStreamAvailable,
    widgetConfig,
  } = props;

  const {
    isLiveWidgetShown,
    isStreamShown,
  } = useHeadlineInplayComponentShowState({
    doForceShowInplayComponent,
    activeSlideId,
    isLiveWidgetAvailable,
    isStreamAvailable,
  });

  const isStreamFloatingEnabled = computed<boolean>(() => !!widgetConfig.value?.liveStreamWidgetFloatingEnabled);
  const isLiveWidgetFloatingEnabled = computed<boolean>(() => !!widgetConfig.value?.sportRadarWidgetFloatingEnabled);

  return {
    isStreamFloatingEnabled,
    isLiveWidgetFloatingEnabled,
    isLiveWidgetShown,
    isStreamShown,
  };
}
