<script lang="ts" setup>
import { toRef } from 'vue';

import { SportSegmentId } from 'web/src/modules/sportline/enums';

import type { HeadlineWideLayoutProps, HeadlineWideLayoutSlots } from './types';
import HeadlineCybersportWideLayout from './HeadlineCybersportWideLayout.vue';
import HeadlineDefaultWideLayout from './HeadlineDefaultWideLayout.vue';

interface WideHeadlineLayoutProps extends HeadlineWideLayoutProps {
  segmentId?: SportSegmentId;
}

const props = defineProps<WideHeadlineLayoutProps>();
defineSlots<HeadlineWideLayoutSlots>();

const segmentId = toRef(props, 'segmentId');
const sportEventDetails = toRef(props, 'sportEventDetails');
const hasInplayComponent = toRef(props, 'hasInplayComponent');
const hasPrimaryMarket = toRef(props, 'hasPrimaryMarket');
</script>

<template>
  <HeadlineCybersportWideLayout
    v-if="segmentId === SportSegmentId.CyberSport"
    :sport-event-details="sportEventDetails"
    :has-inplay-component="hasInplayComponent"
    :has-primary-market="hasPrimaryMarket"
  >
    <template #info-widget="{ setHeight }">
      <slot
        name="info-widget"
        :set-height="setHeight"
      />
    </template>

    <template #info="{ backgroundColor }">
      <slot
        name="info"
        :background-color="backgroundColor"
      />
    </template>

    <template #inplay-component="{ setHeight }">
      <slot
        name="inplay-component"
        :set-height="setHeight"
      />
    </template>
  </HeadlineCybersportWideLayout>
  <HeadlineDefaultWideLayout
    v-else
    :sport-event-details="sportEventDetails"
    :has-inplay-component="hasInplayComponent"
    :has-primary-market="hasPrimaryMarket"
  >
    <template #info="{ backgroundColor }">
      <slot
        name="info"
        :background-color="backgroundColor"
      />
    </template>

    <template #inplay-component="{ setHeight, backgroundColor }">
      <slot
        name="inplay-component"
        :set-height="setHeight"
        :background-color="backgroundColor"
      />
    </template>
  </HeadlineDefaultWideLayout>
</template>
