<script setup lang="ts">
import { computed, toRef } from 'vue';

import type {
  BetlineMatchStatisticsMatch,
  BetlineMatchStatisticsTeam,
} from 'web/src/modules/sportline/types/rest';
import MatchesStatisticCompetitors
  from 'web/src/modules/sportline/submodules/event-details/components/statistic/MatchesStatisticCompetitors.vue';
import MatchStatisticResultIndicator
  from 'web/src/modules/sportline/submodules/event-details/components/statistic/MatchStatisticResultIndicator.vue';
import { isTeamWinMatch } from 'web/src/modules/sportline/utils/rest/statistic';

interface LastMatchesStatisticProps {
  teams: [BetlineMatchStatisticsTeam, BetlineMatchStatisticsTeam];
  hostMatches?: BetlineMatchStatisticsMatch[];
  guestMatches?: BetlineMatchStatisticsMatch[];
}

const props = defineProps<LastMatchesStatisticProps>();

const teams = toRef(props, 'teams');
const hostMatches = toRef(props, 'hostMatches', []);
const guestMatches = toRef(props, 'guestMatches', []);

const host = computed<Maybe<BetlineMatchStatisticsTeam>>(() => teams.value[0] ?? null);
const guest = computed<Maybe<BetlineMatchStatisticsTeam>>(() => teams.value[1] ?? null);

const hostWinPercentage = computed<number>(() => {
  const total = hostMatches.value.length;
  const team = host.value;
  const wins = hostMatches.value.reduce<number>((result, match) => (result + Number(isTeamWinMatch(team, match))), 0);
  const percentage = (100 / total) * wins;

  return Math.round(percentage * 10) / 10;
});
const guestWinPercentage = computed<number>(() => {
  const total = guestMatches.value.length;
  const team = guest.value;
  const wins = guestMatches.value.reduce<number>((result, match) => (result + Number(isTeamWinMatch(team, match))), 0);
  const percentage = (100 / total) * wins;

  return Math.round(percentage * 10) / 10;
});
</script>

<template>
  <div v-auto-id="'LastMatchesStatistic'" :class="$style['last-matches-statistic']">
    <MatchesStatisticCompetitors
      :teams="teams"
    />

    <div :class="$style['last-matches-statistic-result-indicators__container']">
      <div :class="$style['last-matches-statistic-result-indicators']">
        <MatchStatisticResultIndicator
          v-for="match in hostMatches"
          :key="match.id ?? undefined"
          :match="match"
          :displayed-for-team="host"
          :class="$style['last-matches-statistic-result-indicator']"
        />
      </div>

      <div :class="$style['last-matches-statistic-result-indicators']">
        <MatchStatisticResultIndicator
          v-for="match in guestMatches"
          :key="match.id ?? undefined"
          :match="match"
          :displayed-for-team="guest"
          :class="$style['last-matches-statistic-result-indicator']"
        />
      </div>
    </div>

    <div :class="$style['last-matches-statistic-result-percentages__container']">
      <span
        :class="$style['last-matches-statistic-result-percentages']"
      >{{ hostWinPercentage }}%</span>
      <span
        :class="[
          $style['last-matches-statistic-result-percentages'],
          $style['last-matches-statistic-result-percentages--central'],
        ]"
      >{{ $t('WEB2_MATCH_STATISTIC_WINS_PERCENTAGE') }}</span>
      <span
        :class="$style['last-matches-statistic-result-percentages']"
      >{{ guestWinPercentage }}%</span>
    </div>

    <div :class="$style['last-matches-statistic-result-graph__container']">
      <div
        :class="[
          $style['last-matches-statistic-result-graph'],
          $style['last-matches-statistic-result-graph--left'],
        ]"
      >
        <div
          :class="[
            $style['last-matches-statistic-result-graph__filler'],
            $style['last-matches-statistic-result-graph__filler--left'],
          ]"
          :style="{ width: `${hostWinPercentage}%` }"
        />
      </div>
      <div
        :class="[
          $style['last-matches-statistic-result-graph'],
          $style['last-matches-statistic-result-graph--right'],
        ]"
      >
        <div
          :class="[
            $style['last-matches-statistic-result-graph__filler'],
            $style['last-matches-statistic-result-graph__filler--right'],
          ]"
          :style="{ width: `${guestWinPercentage}%` }"
        />
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
.last-matches-statistic {
  padding: 4px;
  background-color: var(--Layer2);
  border-radius: 5px;
}

.last-matches-statistic-result-indicators {
  display: flex;

  &__container {
    display: flex;
    justify-content: space-between;
    padding: 12px 4px 4px;
    border-top: 1px solid var(--Layer1);
  }
}

.last-matches-statistic-result-indicator {
  padding: 0 4px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.last-matches-statistic-result-percentages {
  @include medium\12\12;

  color: var(--TextDefault);

  &__container {
    display: flex;
    justify-content: space-between;
    padding: 8px 4px;
  }

  &--central {
    color: var(--TextPrimary);
  }
}

// @TODO confrontation chart component
.last-matches-statistic-result-graph {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: var(--Highlight);
  border-radius: 2px;

  &__container {
    display: flex;
    justify-content: space-between;
    padding: 0 4px 12px;
  }

  &--left {
    margin-right: 2px;
  }

  &--right {
    margin-left: 2px;
  }

  &__filler {
    height: 100%;
    border-radius: 2px;

    &--left {
      position: absolute;
      right: 0;
      background: var(--StatsA);
    }

    &--right {
      position: absolute;
      left: 0;
      background: var(--StatsB);
    }
  }
}
</style>
