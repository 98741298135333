<script lang="ts" setup>
import type { SportElement, SportlineEventElement } from 'web/src/modules/sportline/types';
import { BreadcrumbType } from 'web/src/components/Breadcrumbs/VBreadcrumb/enums';
import VBreadcrumb from 'web/src/components/Breadcrumbs/VBreadcrumb/VBreadcrumb.vue';
import { PopperApplyTarget } from 'web/src/components/Popper/VPopper/enums';
import { BreadcrumbId } from 'web/src/modules/sportline/submodules/breadcrumbs/enums';

import BreadcrumbsRelatedEvents from './BreadcrumbsRelatedEvents.vue';

interface RelatedEventsBreadcrumbsItemProps {
  sportElement: Maybe<SportElement>;
  relatedEventsList: SportlineEventElement[];
  activeEventId: Optional<string>;
  title: string;
  position: number;
  open: boolean;
}

interface RelatedEventsBreadcrumbsItemEmits {
  (e: 'toggle', id: Maybe<BreadcrumbId>): void;
}

defineProps<RelatedEventsBreadcrumbsItemProps>();
const emit = defineEmits<RelatedEventsBreadcrumbsItemEmits>();
</script>

<template>
  <VBreadcrumb v-auto-id="'RelatedEventsBreadcrumbsItem'"
    :type="BreadcrumbType.DEFAULT"
    :title="title"
    :position="position"
    :open="open"
    :apply-target="PopperApplyTarget.Content"
    :can-expand="relatedEventsList.length > 0"
    controlled
    @toggle="emit('toggle', BreadcrumbId.Events)"
  >
    <template
      v-if="sportElement && relatedEventsList.length > 0"
      #default="{ forceHide }"
    >
      <BreadcrumbsRelatedEvents
        :sport-element="sportElement"
        :related-events="relatedEventsList"
        :active-event-id="activeEventId"
        is-market-types-selection-enabled
        @click-event="emit('toggle', BreadcrumbId.Events);forceHide();"
      />
    </template>
  </VBreadcrumb>
</template>
