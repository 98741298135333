<script setup lang="ts">
import {
  computed,
  ref,
  toRef,
} from 'vue';

import type {
  PopperModifier,
  VPopperRef,
} from 'web/src/components/Popper/VPopper/types';
import type { SportEventDetailsRelatedEventsDropdownRef } from 'web/src/modules/sportline/submodules/event-details/views/toolbar/types';
import { PopperPosition } from 'web/src/components/Popper/VPopper/enums';
import VPopper from 'web/src/components/Popper/VPopper/VPopper.vue';

interface RelatedEventsDropdownProps {
  offsetTop?: number;
}

interface RelatedEventsDropdownEmits {
  (e: 'opened'): void;
  (e: 'closed'): void;
}

const props = defineProps<RelatedEventsDropdownProps>();
const emit = defineEmits<RelatedEventsDropdownEmits>();

const offsetTop = toRef(props, 'offsetTop', 0);

const popper = ref<Optional<VPopperRef>>();
const wrapper = ref<Optional<HTMLElement>>();
const isOpened = ref(false);

const offset = '1'
  ? ref(-8)
  : offsetTop;
const popperModifiers = computed<PopperModifier[]>(() => [{
  name: 'offset',
  options: {
    offset: [0, offset.value],
  },
}]);

function hide(): void {
  popper.value?.hide();
}

function onShow(): void {
  isOpened.value = true;
  emit('opened');
}

function onHide(): void {
  isOpened.value = false;
  emit('closed');
}

defineExpose<SportEventDetailsRelatedEventsDropdownRef>({ hide });
</script>

<template>
  <div v-auto-id="'RelatedEventsDropdown'"
    ref="wrapper"
    :class="$style['sport-event-details-dropdown']"
  >
    <VPopper
      ref="popper"
      trigger="clickToToggle"
      :position="PopperPosition.BOTTOM"
      :modifiers="popperModifiers"
      is-full-width
      is-full-height
      :has-offset="false"
      @show="onShow"
      @hide="onHide"
    >
      <template #reference>
        <slot
          name="reference"
          :opened="isOpened"
        >
          <span />
        </slot>
      </template>

      <div :class="$style['sport-event-details-dropdown__content']">
        <Transition
          :enter-active-class="$style['fade-enter-active']"
          :enter-from-class="$style['fade-enter-from']"
          :enter-to-class="$style['fade-enter-to']"
          :leave-active-class="$style['fade-leave-active']"
          :leave-from-class="$style['fade-leave-from']"
          :leave-to-class="$style['fade-leave-to']"
          name="fade"
        >
          <div
            v-show="isOpened"
            :class="$style['sport-event-details-dropdown__background']"
            @click="hide"
          />
        </Transition>

        <Transition
          :enter-active-class="$style['slide-enter-active']"
          :enter-from-class="$style['slide-enter-from']"
          :enter-to-class="$style['slide-enter-to']"
          :leave-active-class="$style['slide-leave-active']"
          :leave-from-class="$style['slide-leave-from']"
          :leave-to-class="$style['slide-leave-to']"
          name="slide"
        >
          <div v-if="isOpened">
            <slot />
          </div>
        </Transition>
      </div>
    </VPopper>
  </div>
</template>

<style module lang="scss">
.sport-event-details-dropdown {
  height: 100%;

  &__content {
    right: 0;
    z-index: z-index(dropdown-menu);
    height: 100%;
    background-color: var(--OpacityLayer0);
  }

  &__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    opacity: 0.7;
  }
}

.slide-enter-active {
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
}

.slide-leave-active {
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-duration: 0.2s;
}

.slide-enter-to,
.slide-leave-from {
  transform: scaleY(1);
  transform-origin: top;
}

.slide-enter-from,
.slide-leave-to {
  overflow: hidden;
  transform: scaleY(0);
  transform-origin: top;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.1s;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
