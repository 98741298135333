import type { Ref } from 'vue';
import { ref } from 'vue';

interface SwipeByDragAndDropComposable {
  container: Ref<HTMLElement | undefined>;
  swipeByDnDonMouseDown(event: MouseEvent): void;
}

export default function useSwipeByDragAndDropComposable(): SwipeByDragAndDropComposable {
  const container: Ref<HTMLElement | undefined> = ref();

  const initClientX: Ref<number> = ref(0);

  function swipeByDnDonMouseDown(event: MouseEvent): void {
    initClientX.value = event.clientX;
    window.addEventListener('mousemove', swipeByDnDonMouseMove);
    window.addEventListener('mouseup', swipeByDnDonMouseUp);
  }

  function swipeByDnDonMouseMove(event: MouseEvent): void {
    if (!container.value) return;
    container.value.scrollBy({
      left: initClientX.value - event.clientX,
    });
    initClientX.value = event.clientX;
  }

  function swipeByDnDonMouseUp(): void {
    initClientX.value = 0;
    window.removeEventListener('mousemove', swipeByDnDonMouseMove);
    window.removeEventListener('mouseup', swipeByDnDonMouseUp);
  }

  return {
    container,
    swipeByDnDonMouseDown,
  };
}
