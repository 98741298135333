import type { Ref } from 'vue';

// types
import type {
  OddinAdditionalQueryStringParameters,
} from 'web/src/modules/framed-app/components/oddin/types';
import type { IFrameWidgetName } from 'web/src/modules/framed-app/types';
// constants
import {
  postMessageBusInitiator,
} from 'web/src/modules/framed-app/components/oddin/LiveMatchTracker/constants';
import {
  oddinLiveMatchTrackerIframeWidgetName,
} from 'web/src/modules/framed-app/constants';
// utils
import {
  LiveOddinPostMessageEvent,
} from 'web/src/modules/framed-app/components/oddin/events';

import type {
  OddinWidgetEmits,
} from '../types';
import {
  createAdditionalQueryStringParameters,
  createFramedAppWidgetUrl,
  useOddinWidgetIFrameAppearance,
  useOddinWidgetPostMessageBus,
} from './composables';

interface UseOddinLiveWidgetProps {
  iframeSrc: Ref<string>; // oddin iframe src from event data
}

interface UseOddinLiveWidgetComposable {
  isWidgetLoaded: Ref<boolean>;
  minHeight: Ref<number | undefined>;
  iframeName: IFrameWidgetName;
  iframedAppSrc: Ref<string | undefined>;
  additionalQueryStringParameters: Ref<OddinAdditionalQueryStringParameters>;
  onIframeMounted(): void;
  onIframeUnmounted(): void;
}

export function useOddinLiveWidget(
  props: UseOddinLiveWidgetProps,
  emit: OddinWidgetEmits,
): UseOddinLiveWidgetComposable {
  const { iframeSrc } = props;

  const iframeName = oddinLiveMatchTrackerIframeWidgetName;

  const {
    createPostMessageBus,
    disposePostMessageBus,
  } = useOddinWidgetPostMessageBus(postMessageBusInitiator, iframeName);
  const {
    isWidgetLoaded,
    minHeight,
    subscribeToPostMessageBusEvents,
  } = useOddinWidgetIFrameAppearance(emit, LiveOddinPostMessageEvent);

  return {
    isWidgetLoaded,
    minHeight,
    iframeName,
    iframedAppSrc: createFramedAppWidgetUrl(iframeName),
    additionalQueryStringParameters: createAdditionalQueryStringParameters(iframeSrc),
    onIframeMounted(): void {
      subscribeToPostMessageBusEvents(createPostMessageBus());
    },
    onIframeUnmounted(): void {
      disposePostMessageBus();
      emit('change-ready-state', false);
      emit('size-changed', {});
    },
  };
}
