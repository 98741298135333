import type {
  BetgeniusDetailsHeadlineWidget,
  LSportsDetailsHeadlineWidget,
  SportEventDetailsHeadlineWidget,
  SportradarLMTDetailsHeadlineWidget,
} from 'web/src/modules/sportline/submodules/event-details/types';
import {
  sportlineWidgetTypeBetgenius,
  sportlineWidgetTypeLsports,
  sportlineWidgetTypeSportradar,
} from 'web/src/modules/sportline/constants/widgets';

export function isSportradarLMTDetailsHeadlineWidget(
  value: SportEventDetailsHeadlineWidget,
): value is SportradarLMTDetailsHeadlineWidget {
  return value.type === sportlineWidgetTypeSportradar;
}

export function isLSportsDetailsHeadlineWidget(
  value: SportEventDetailsHeadlineWidget,
): value is LSportsDetailsHeadlineWidget {
  return value.type === sportlineWidgetTypeLsports;
}

export function isBetgeniusDetailsHeadlineWidget(
  value: SportEventDetailsHeadlineWidget,
): value is BetgeniusDetailsHeadlineWidget {
  return value.type === sportlineWidgetTypeBetgenius;
}
