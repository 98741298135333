<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue';

import { logger } from '@leon-hub/logging';

// composables
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import {
  useDisabledWidgetLoadingController,
  useSportlineEventLiveWidget,
} from 'web/src/modules/sportline/submodules/event-details/composables/widget';
import {
  useOddinLiveWidgetLoadingController,
  useOddinPrematchWidgetLoadingController,
} from 'web/src/modules/sportline/submodules/oddin-event-details/composables';
// constants
import { sportlineWidgetTypeOddin } from 'web/src/modules/sportline/constants/widgets';
// components
import SportlineEventSuspendedInfo from 'web/src/modules/sportline/submodules/event-details/components/finished-info/SportlineEventSuspendedInfo.vue';
import SportEventStatistic from 'web/src/modules/sportline/submodules/event-details/views/SportEventStatistic.vue';
import SportlineEventBreadcrumbs from 'web/src/modules/sportline/submodules/event-details/views/SportlineEventBreadcrumbs.vue';
import SportlineEventPostMatchStatistic from 'web/src/modules/sportline/submodules/event-details/views/SportlineEventPostMatchStatistic.vue';
import SportEventDetailsToolbarDesktop from 'web/src/modules/sportline/submodules/event-details/views/toolbar/SportEventDetailsToolbarDesktop.vue';
import SportEventDetailsToolbarMobile from 'web/src/modules/sportline/submodules/event-details/views/toolbar/SportEventDetailsToolbarMobile.vue';
import SlicedTreeNavigation from 'web/src/modules/sportline/submodules/navigation/views/bet-swipe-navigation/SlicedTreeNavigation.vue';
import OddinLiveWidget from 'web/src/modules/sportline/submodules/oddin-event-details/views/OddinLiveWidget.vue';
import OddinPrematchWidget from 'web/src/modules/sportline/submodules/oddin-event-details/views/OddinPrematchWidget.vue';
import MarketsList from 'web/src/modules/sportline/views/markets-list/MarketsList.vue';

import { useContentHasStatisticsState, useMiniBoardState } from './composables';
import ClosedSportlineEventDetailsHeadline from './headlline/ClosedSportlineEventDetailsHeadline.vue';
import SportEventDetailsHeadlineHolder from './headlline/SportlineEventDetailsHeadlineHolder.vue';
import SwiperSportlineEventDetailsHeadline from './headlline/SwiperSportlineEventDetailsHeadline.vue';
import WideSportlineEventDetailsHeadline from './headlline/WideSportlineEventDetailsHeadline.vue';
import { useSportlineEventDetailsContent } from './useSportlineEventDetailsContent';

if (null) {
  logger.warn('Forbidden sportline event details content component. Use SportEventDetailsCordovaContent instead.');
}

const SportEventRelatedList = '1'
  ? defineAsyncComponent(() => import('web/src/modules/sportline/submodules/event-details/components/related-events/RelatedEventsTable.vue'))
  : defineAsyncComponent(() => import('web/src/modules/sportline/submodules/event-details/components/related-events/RelatedEventsList.vue'));

const SportEventDetailsToolbar = '1'
  ? SportEventDetailsToolbarDesktop
  : SportEventDetailsToolbarMobile;

const {
  isMarketGroupsTabsEnabled,
  isLoaded,
  isShowingLoadingIndicator,
  sportlineEvent,
  relatedSportListElement,
  leagueEventsList,
  defaultLayoutType,
  sport,
  activeEventId,
  isSportlineEventExist,
  isSportlineEventClosed,
  isSportlineEventSuspended,
  isRelatedEventsAvailable,
  suspendedLabel,
  onHeadlineVisibilityChanged,
  onLayoutTypeChanged,
} = useSportlineEventDetailsContent();
const {
  hasMatchStatistic,
  hasPostMatchStatistic,
} = useContentHasStatisticsState();
const {
  isMiniBoardAllowed,
  isMiniBoardShown,
  headerHeight,
} = useMiniBoardState();
const {
  isBetSwipeNavigationLayoutEnabled,
  isBetSwipeEventDetailsLayoutEnabled,
} = useSportlineLayoutSettings();
const {
  liveWidget,
  liveWidgetType,
} = useSportlineEventLiveWidget({ sportlineEvent });

const {
  isWidgetAvailable: isLiveInoWidgetAvailable,
  isWidgetReady: hasLiveInfoWidget,
  setIsWidgetReady: setIsLiveInfoWidgetReady,
} = '1'
  ? useOddinLiveWidgetLoadingController({ liveWidgetType })
  : useDisabledWidgetLoadingController();
const {
  isWidgetAvailable: isStatisticWidgetAvailable,
  isWidgetReady: hasStatisticWidget,
  setIsWidgetReady: setIsStatisticWidgetReady,
} = '1'
  ? useOddinPrematchWidgetLoadingController({ liveWidgetType })
  : useDisabledWidgetLoadingController();

const isPrimaryMarketFiltrationDisabled = computed<boolean>(() => (
  !!'1'
  && (
    (!!null && isBetSwipeEventDetailsLayoutEnabled.value)
    || (!!'1' && hasLiveInfoWidget.value)
  )
));
</script>

<template>
  <div v-auto-id="'SportlineEventDetailsContent'"
    :style="{
      '--sportline-mini-board-height': isMiniBoardAllowed && isMiniBoardShown ? '48px' : '0px',
      '--sportline-header-height': `${headerHeight}px`,
    }"
  >
    <SlicedTreeNavigation
      v-if="null && isBetSwipeNavigationLayoutEnabled"
      :class="$style['sportline-event-content__navigation']"
      :league-events-list="leagueEventsList"
      :active-event-id="activeEventId"
    />
    <template v-else>
      <div
        v-if="'1'"
        :class="$style['sportline-event-content__breadcrumbs-holder']"
      >
        <SportlineEventBreadcrumbs />
      </div>

      <SportEventDetailsToolbar
        :has-related-events="isSportlineEventExist && isRelatedEventsAvailable"
      >
        <template #relatedList="{ hide }">
          <SportEventRelatedList
            v-if="isLoaded"
            :sport-element="relatedSportListElement"
            :active-id="activeEventId"
            @click-event="hide"
          />
        </template>
      </SportEventDetailsToolbar>
    </template>

    <template v-if="isSportlineEventExist">
      <ClosedSportlineEventDetailsHeadline
        v-if="isSportlineEventClosed"
        :class="$style['sportline-event-content__post-match-statistic-headline']"
      />
      <SportEventDetailsHeadlineHolder
        v-else
        @visibility-changed="onHeadlineVisibilityChanged"
      >
        <WideSportlineEventDetailsHeadline
          v-if="'1'"
          :is-live-widget-widget-available="isLiveInoWidgetAvailable"
          :is-primary-market-filtration-disabled="isPrimaryMarketFiltrationDisabled"
          :has-live-info-widget="hasLiveInfoWidget"
        >
          <template
            v-if="'1' && liveWidget && liveWidgetType === sportlineWidgetTypeOddin && liveWidget.iframes?.live"
            #live-info-widget="{ isDisplayed, setHeight }"
          >
            <OddinLiveWidget
              v-show="hasLiveInfoWidget && isDisplayed"
              :iframe-src="liveWidget.iframes.live"
              @change-ready-state="setIsLiveInfoWidgetReady"
              @size-changed="setHeight?.($event.height)"
            />
          </template>
        </WideSportlineEventDetailsHeadline>
        <SwiperSportlineEventDetailsHeadline
          v-else
          :is-live-widget-widget-available="isLiveInoWidgetAvailable"
          :is-primary-market-filtration-disabled="isPrimaryMarketFiltrationDisabled"
          :has-live-info-widget="hasLiveInfoWidget"
        >
          <template
            v-if="'1' && liveWidget && liveWidgetType === sportlineWidgetTypeOddin && liveWidget.iframes?.live"
            #live-info-widget="{ isDisplayed, setHeight }"
          >
            <OddinLiveWidget
              v-show="hasLiveInfoWidget && isDisplayed"
              :iframe-src="liveWidget.iframes.live"
              @change-ready-state="setIsLiveInfoWidgetReady"
              @size-changed="setHeight?.($event.height)"
            />
          </template>
        </SwiperSportlineEventDetailsHeadline>
      </SportEventDetailsHeadlineHolder>

      <SportlineEventSuspendedInfo
        v-if="isSportlineEventSuspended"
        :class="$style['sportline-event-content__suspended-info']"
        :label="suspendedLabel"
        :is-paused="!isSportlineEventClosed"
        is-short
      />

      <MarketsList
        :sport-event="sportlineEvent"
        :representation-sport-family="sport?.representation.family"
        :default-layout-type="defaultLayoutType"
        :is-loaded="isLoaded"
        :is-showing-loading-indicator="isShowingLoadingIndicator"
        :is-market-groups-tabs-enabled="isMarketGroupsTabsEnabled"
        is-layout-type-change-enabled
        :is-statistic-widget-available="isStatisticWidgetAvailable"
        :has-statistic="hasMatchStatistic"
        :has-statistic-widget="hasStatisticWidget"
        :is-primary-market-filtration-disabled="isPrimaryMarketFiltrationDisabled"
        @layout-type-changed="onLayoutTypeChanged"
      >
        <template
          v-if="'1' && liveWidget && liveWidgetType === sportlineWidgetTypeOddin && liveWidget.iframes?.prematch"
          #statistic-widget="{ isDisplayed }"
        >
          <OddinPrematchWidget
            v-show="hasStatisticWidget && isDisplayed"
            :iframe-src="liveWidget.iframes.prematch"
            @change-ready-state="setIsStatisticWidgetReady"
          />
        </template>

        <template
          v-if="hasMatchStatistic"
          #statistic
        >
          <SportEventStatistic :class="$style['sportline-event-content__statistic']" />
        </template>
        <template
          v-if="isSportlineEventSuspended && hasPostMatchStatistic"
          #post-statistic
        >
          <SportlineEventPostMatchStatistic />
        </template>
      </MarketsList>
    </template>
    <SportlineEventPostMatchStatistic
      v-else-if="hasPostMatchStatistic"
      is-display-headline
    />
    <SportlineEventSuspendedInfo
      v-else
      :class="$style['sportline-event-content__suspended-info']"
    />
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .sportline-event-content {
    &__navigation {
      width: 100%;
      margin-bottom: 12px;
    }

    &__breadcrumbs-holder {
      min-height: 40px;
    }

    &__suspended-info {
      @include z-index(base);

      position: relative;
      margin: if($isDesktop, 0, 16px);
      cursor: default;
    }

    &__post-match-statistic-headline {
      margin: if($isDesktop, 0 0 8px, 0 8px);
    }

    &__statistic {
      &:not(&:last-child) {
        margin-bottom: 4px;
      }
    }
  }
}
</style>
