import type { Ref } from 'vue';

import type { Sport } from 'web/src/modules/sportline/types';
import { useSportBackgroundColor } from 'web/src/modules/sportline/composables/sport';

interface UseHeadlineDefaultLayoutProps {
  sport: Ref<Sport>;
}

interface UseHeadlineDefaultLayoutComposable {
  backgroundColor: Ref<Maybe<string>>;
}

export function useHeadlineDefaultLayout(
  props: UseHeadlineDefaultLayoutProps,
): UseHeadlineDefaultLayoutComposable {
  const { sport } = props;

  const { backgroundColor } = useSportBackgroundColor({ sport });

  return {
    backgroundColor,
  };
}
